import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { Link, useNavigate } from 'react-router-dom';
import "../styles/login.css";
import { UserAuth } from '../context/AuthContext';
import Filter from 'bad-words';
import { min } from 'moment';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';

let filter = new Filter();

// Function that returns the signup page
const Signup = () => {
  const [country, setCountry] = useState('')
  const options = useMemo(() => countryList().getData(), [])
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [priv, setPriv] = useState('');
  const [error, setError] = useState('')
  const [experience, setExperience] = useState('');
  const [bio, setBio] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const { createUser } = UserAuth();

  const navigate = useNavigate();

  const changeHandler = value => {
    setCountry(value)
  }

  function displayError(msg) {
    let errorAlert = document.getElementById('errorText');
    errorAlert.innerHTML = msg;
    errorAlert.style.backgroundColor = "rgb(250, 166, 166)";
    errorAlert.style.padding = "5px";
    errorAlert.style.margin = "0 10px 5px 10px";
    errorAlert.style.borderRadius = "3px";
    errorAlert.style.fontSize = "1rem";
    errorAlert.style.textAlign = "center";

    setTimeout(() => {
      errorAlert.innerHTML = "";
      errorAlert.style = "";
    }, 7000);
  }

  // Function that handles the submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Check if passwords match
      if (confirmpassword !== password) {
        displayError("Passwords do not match");
        return;
      }

      // check password requirements
      const minPasswordLength = 6;
      if (password.length < minPasswordLength) {
        displayError("Password must be at least 6 characters long");
        return;
      }

      // check if email already exists
      const signInMethods = await fetchSignInMethodsForEmail(auth, email)
      if (signInMethods.error) {
        displayError("Could not verify if email address exists");
        return;
      }
      if (signInMethods.length > 0) {
        displayError("Email already exists");
        return;
      }
    
      // Create user and navigate to the home page assuming everything was successful
      await createUser((filter.clean(firstname) + " " + filter.clean(lastname)), email, password, experience, country, "public");

      navigate('/');

    } catch (e) {
      setError(e.message);
      displayError(e.message);
    }
  };

  const handleCheckExperience = (e) => {
    setExperience(e.target.value);
  }

  return (
    <form onSubmit={handleSubmit} className='formContainer'>

      <div className='container'>
        <div className='loginContainer'>
          <h1 className='loginText'>Sign Up</h1>
          <h3 id="errorText"> </h3>
          <div className="nameInputContainer">
            <label className="form-label" htmlFor="firstname">First Name <span style={{color: "red", fontSize: "20px"}}>*</span></label>
            <input type="text" id="firstname" className="form-control" name="name" onChange={(e) => setFirstName(e.target.value)} required maxlength="15" pattern="[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð -]+"/>
          </div>

          <div className="nameInputContainer">
            <label className="form-label" htmlFor="lastname">Last Name <span style={{color: "red", fontSize: "20px"}}>*</span></label>
            <input type="text" id="lastname" className="form-control" name="name" onChange={(e) => setLastName(e.target.value)} required maxlength="20" pattern="[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð -]+"/>
          </div>

          <div className="emailInputContainer">
            <label htmlFor="emailAddress">Email address <span style={{color: "red", fontSize: "20px"}}>*</span></label>
            <input type="email" id="emailAddress" className="form-control" name="email" onChange={(e) => setEmail(e.target.value)} required/>
          </div>
          <div className="passwordInputContainer">
            <label htmlFor="form2Example2">Password <span style={{color: "red", fontSize: "20px"}}>*</span></label>
            <input type="password" id="password" className="form-control" name="password" onChange={(e) => setPassword(e.target.value)} required minlength="6"/>
          </div>

          <div className="passwordInputContainer">
            <label htmlFor="form2Example2">Confirm Password <span style={{color: "red", fontSize: "20px"}}>*</span></label>
            <input type="password" id="confirmpassword" className="form-control" name="confirmpassword" onChange={(e) => setConfirmPassword(e.target.value)} required minlength="6"/>
          </div>

          {/* <div className="privacyInputContainer">
            <h5>Select your account's privacy level (you can change this later)</h5>
            <input type="radio" id="private" name="privacy" onChange={(e) => setPriv("private")} required />
            <label htmlFor="private">Private</label>
            <input type="radio" id="public" name="privacy" onChange={(e) => setPriv("public")} required />
            <label htmlFor="public">Public</label>
          </div> */}

          <button type="submit" className="signInBtn">Sign up</button>


          <div className="otherSignOptionContainer">
            <div className='noAccountContainer'>
              <h6 className="noAccountText">Already have an account?</h6>
              <Link className="Link" to="/login"><h6 className='registertext'>Sign in here!</h6></Link>
            </div>

          </div>

        </div>
      </div>
    </form>
  )

}

export default Signup;