import React from 'react';
import '../../styles/loading.css';
import LoadingLottie from './load';
import Logo from '../../imgs/UXwashere_logo.png';

const Loading = () => {

    return (
        <div className='loading'>
            <div className='logo-loading-container'>
                <img className="logo-loading" src={Logo}/>
            </div>
            <div className='progressbar'>
            <LoadingLottie/>
            </div>
      </div>
    );
};

export default Loading;