import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
  FacebookAuthProvider,
} from 'firebase/auth';
import { auth, fbFirestore } from '../firebase/firebaseConfig';
import {
  updateDoc,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import Loading from '../components/loading/loading';



const UserContext = createContext();


export const AuthContextProvider = ({ children }) => {

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  // handleRegister for email and password
  //const createUser = async (name, email, password, experience, count, bio, linkedin) => {
  const createUser = async (name, email, password, experience, count, priv) => {
    if(!priv){
      priv = "public";
    }
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);

      let current = new Date();
      await setDoc(doc(fbFirestore, "users", res.user.uid), {
        uid: res.user.uid,
        displayName: name,
        country: count,
        photoURL: "https://firebasestorage.googleapis.com/v0/b/ux-was-here.appspot.com/o/profile_img%2Fprofile.png?alt=media&token=70a5f7b0-9379-48e7-9429-34be538d0a8a",
        email: email,
        bio: "",
        privacy: priv,
        experience: experience,
        isOnline: true,
        joinDate: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
        friends: [],
        followers: [],
        following: [],
        requests: [],
        isBanned: false,
        role: "user",
        communitiesJoined: [],
        localModeratorCommunities : [],
      });


      await updateProfile(auth.currentUser, {
        displayName: name,
        photoURL: 'https://firebasestorage.googleapis.com/v0/b/ux-was-here.appspot.com/o/profile_img%2Fprofile.png?alt=media&token=70a5f7b0-9379-48e7-9429-34be538d0a8a'
      }).catch(
        (err) => console.log(err)
      );


    } catch (err) {
      console.log(err);
    }


  };

  // handle login with email and password
  const signIn = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password);
    if (auth.currentUser.role === "banned") {
      alert("You are banned from the app");
      return await signOut(auth);
    }
  }

  // handle reset password 
  const resetP = (email) => {
    return sendPasswordResetEmail(auth, email)
  }

  // handle sign out 
  const logout = () => {
    return signOut(auth);
  }

  // renders everytime current user is updated
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  // calls loading page 
  if (loading) {
    return <Loading />;
  }

  return (
    <UserContext.Provider value={{ createUser, user, logout, signIn, resetP }}>
      {children}
    </UserContext.Provider>
  );
};

// Google authentication and create user collection.
export const googleSignIn = async () => {
  const googleAuthProvider = new GoogleAuthProvider();
  try {
    const res = await signInWithPopup(auth, googleAuthProvider);
    const userRef = doc(fbFirestore, "users", res.user.uid);
    const userSnap = await getDoc(userRef);
    if (!userSnap.exists()) {
      
      let current = new Date();
      await setDoc(doc(fbFirestore, "users", res.user.uid), {
        uid: res.user.uid,
        displayName: res.user.displayName,
        email: res.user.email,
        bio: "",
        privacy: 'public',
        experience: '',
        photoURL: res.user.photoURL,
        photoPath: "",
        isOnline: true,
        joinDate: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
        friends: [],
        followers: [],
        following: [],
        requests: [],
        isBanned: false,
        role: "user",
        communitiesJoined: [],
        localModeratorCommunities : [],
      });
    } else {
      if (auth.currentUser.role === "banned") {
        alert("You are banned from the app");
        return signOut(auth);
      }
      await updateDoc(doc(fbFirestore, "users", res.user.uid), {
        isOnline: true,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}

// Facebook authentication and create user collection
export const facebookSignIn = async () => {
  const facebookAuthProvider = new FacebookAuthProvider();
  try {
    const res = await signInWithPopup(auth, facebookAuthProvider);
    const userRef = doc(fbFirestore, "users", res.user.uid);
    const userSnap = await getDoc(userRef);
    let current = new Date();
    if (!userSnap.exists()) {
      await setDoc(doc(fbFirestore, "users", res.user.uid), {
        uid: res.user.uid,
        displayName: res.user.displayName,
        email: res.user.email,
        bio: "",
        privacy: 'public',
        experience: '',
        photoURL: res.user.photoURL,
        photoPath: "",
        isOnline: true,
        joinDate: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
        friends: [],
        followers: [],
        following: [],
        requests: [],
        isBanned: false,
        role: "user",
        communitiesJoined: [],
        localModeratorCommunities : [],
      });
    } else {
      if (auth.currentUser.role === "banned") {
        alert("You are banned from the app");
        return signOut(auth);
      }
      await updateDoc(doc(fbFirestore, "users", res.user.uid), {
        isOnline: true,
      });
    }

  } catch (err) {
    console.error(err.message);
  }
}


export const UserAuth = () => {
  return useContext(UserContext);
};

