import React, {Component} from 'react';
import Lottie from 'react-lottie';
import animationCommunity from '../lotties/community.json';

import '../../styles/about.css';

class CommunityLotties extends Component {
    render() {
        const defaultOptions = {
          loop: true,
          autoplay: true,
          animationData: animationCommunity,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        };

        return (
            <div className='imageFeature'>
              <Lottie options={defaultOptions}/>
              </div>
          );
        }
}

export default CommunityLotties;