const TextContent = {
    tagLine: 'Share . Inspire . Connect',
    tagLineDesc: 'The UX community platform for',
    contentData1: '"All it takes is to be part of the solution, not the problem anymore."',
    createPostDesc: 'Facilitate interactive thread-based communication and drive rich conversations',
    sharePostDesc: 'Share contents to other social platforms like LinkedIn and Twitter',
    connectCommunityDesc: 'Message other users in this community and make connections',
}

Object.freeze(TextContent); //This line of code just makes your object as a constant. No values can be updated.

export default TextContent;