import React, {Component} from 'react';
import Lottie from 'react-lottie';
import animationShare from '../lotties/share_lottie.json';

import '../../styles/homepage.css';

class ShareLotties extends Component {
    render() {
        const defaultOptions = {
          loop: true,
          autoplay: true,
          animationData: animationShare,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        };

        return (
            <div className='imageFeature'>
              <Lottie options={defaultOptions}/>
              </div>
          );
        }
}

export default ShareLotties;