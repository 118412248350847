import React from 'react'
import puzzle from '../imgs/image-48@2x.png'
import logo from '../imgs/ux-was-here--website-2@2x.png'
import kilroy from '../imgs/pngwing-1@2x.png'
import chat from '../imgs/ellipse-25@2x.png'
import network from '../imgs/ellipse-251@2x.png'
import hiring from '../imgs/ellipse-252@2x.png'
import event from '../imgs/ellipse-253@2x.png'
import "../styles/email.css";
import '../styles/comingSoon.scss'
import mainImage from "../imgs/comingsoonmainimage.svg";

import Form from '../components/comingsoon/comingSoonForm'
import Success from '../components/comingsoon/comingSoonFormSuccess'
import Confetti from 'react-confetti'

import { useEffect, useState, useRef } from 'react'

export default function Comingsoon() {

  const errorRef = useRef(null);
  const success = useRef(null);
  const [fullStory, setFullStory] = useState(false);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");  

  useEffect(() => {
    // empty for now
  }, []);

  function handleStory() {
    setFullStory(!fullStory);
  }

  return (
    <div className="comingsoonContainer">
      <Confetti recycle={false} wind={0.03} numberOfPieces={400}/>
    <header className="comingsoonHeader">
    <a href='https://www.uxwashere.com'><img src={logo} className="logo" alt="logo" /></a>
    </header>
    <section id="comingsoonSignup">
      <img id="puzzleImage" 
        src={puzzle}
        alt="puzzle image"
      />
      <h1 className='dm-sans'><b>You're in!</b></h1>
      <div className="signup-div">
      <Success />
      </div>
    </section>
    <section className="imageSection">
      <img src={mainImage} id="mainImage" alt="mainImage" />
    </section>
    <section className="comingsoonMain">
      <div className='mainHeader'>
      <h1 id="whatWeOffer" className="dm-sans"><b>What we offer</b></h1>
      <h4 className="dm-sans">At UX Was Here, we're redefining UX collaboration - together.</h4>
      </div>

      <div className="mainDiv right">
        <img 
          src={network}
          alt="network image"
        />
        <h4>Community Forum</h4>
        <p>Join lively discussions, ask questions, and </p>
        <p>share your knowledge with a community </p>
        <p>that thrives on collaboration.</p>
      </div>
      <div className="mainDiv left">
        <img 
          src={hiring}
          alt="hiring image"
        />
        <h4>Job Board</h4>
        <p>Explore curated job listings that match</p>
        <p> your skills, or post open roles to reach</p>
        <p> the brightest UX talents.</p>
      </div>
      <div className="mainDiv right">
        <img 
          src={chat}
          alt="chat image"
        />
        <h4>Messaging</h4>
        <p>Engage in insightful conversations with</p>
        <p> fellow UX enthusiasts through private</p>
        <p> chats and group discussions.</p>
      </div>
      <div className="mainDiv left">
        <img
          src={event}
          alt="event image"
        />
        <h4>Events</h4>
        <p>Stay up-to-date with exciting UX workshops,</p>
        <p> talks, and networking events tailored to </p>
        <p>inspire and connect.</p>
      </div>
    </section>
    <section className="comingsoonInfo">
      <div id='kilroyContainer'>
      <img src={kilroy} id="kilroy" alt="kilroy" />
      </div>

      <div className="infoDiv">
        <h2 className='dm-sans'>Our Story</h2>
        {fullStory ?

          <div className="fullStory">
          <p>Inspired by the playful graffiti mark "<b>Kilroy was here,</b>" our journey began with a simple observation in a crowded company cafeteria. Serving approximately 1,500 people every day, lunchtime turned into chaos, as employees scrambled to select food and make payments.</p>
          <p>The experience was fraught with frustration, inefficiency, and a shared desire for something better.</p>
          <p>That's when I realized: "<b>I'm a UX! I can help with this!</b>" Using my skills, I decided to take action and address this everyday problem that resonated with so many. </p>
          <p>Working tirelessly after hours, I crafted a solution and eagerly put it to the test. In a guerilla-style experiment, I rearranged the food lines, creating a more efficient flow. I left only one clue behind—a mark that proclaimed, "<b>UX was here!</b>"</p>
          <p>The next day, the results were phenomenal. The once chaotic experience transformed, sparking conversations, empowering employees to share their concerns, and even forging a new internal team dedicated to improving the livelihood of our colleagues. </p>
          <p>This simple yet profound experiment became one of the proudest moments in my career. It wasn't just about improving a lunch line; it was a demonstration of how one person's determination and love for helping others could create a chain reaction of positive change.</p>
          <p>And so, our platform was born—a space designed to connect, empower, and inspire UX professionals. Here, we carry on the spirit of "<b>UX was here,</b>" championing creativity, collaboration, and solutions that make a real difference.</p>
          </div>
          :
          <div className="shortStory"> 
            <p>Inspired by the playful graffiti mark "<b>Kilroy was here,</b>" our journey began with a simple observation in a crowded company cafeteria.</p>
            <p>This simple yet profound experiment became one the proudest moments in my career. It wasn't just about improving a lunch line; it was a demonstration of how one person's determination and love for helping others could create a chain reaction of positive change.</p>
            <p>And so, our platform was born - a space designed to connect, empower, and inspire UX professionals. Here, we carry on the spirit of "<b>UX was here</b>" championing creativity, collaboration, and solutions that make a real difference.</p>
          </div>
        }
        <button className="fullStoryButton" onClick={handleStory}>
        {fullStory ? 
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 14L12 9L17 14H7Z" fill="black"/>
          </svg> /* svg for up arrow */
          : 
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10L12 15L17 10H7Z" fill="black"/>
          </svg> /* svg for down arrow */
        } 
        {fullStory ? 'View Less': 'View Full Story'}  
        </button>

      </div>
    </section>
    {/* <footer className="comingsoonFooter">
      <div className="comingsoonFooterContainer">
        <h2 className="Staatliches" id="footerTitle">UX WAS HERE.</h2>
        <h4>Be the first to know</h4>
        <p>Sign up to our newsletter to stay up to date with updates and newsletters!</p>
        <Form id="2" /> 
      </div>
    </footer> */}
  </div>
  )
}
