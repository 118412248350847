import React, { /* Component,*/ useEffect } from 'react';
import { Routes, Route, useLocation, /*useParams */} from 'react-router-dom';
// import { updateEmail, updateProfile, updatePassword, reauthenticateWithCredential, EmailAuthProvider, reauthenticateWithPopup } from "firebase/auth";
// import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import {/* collection, getDoc, getDocs, query, limit, where, and, or, addDoc, serverTimestamp, */doc, updateDoc, /*increment, setDoc, arrayUnion, arrayRemove, orderBy, startAfter, deleteDoc, collectionGroup, onSnapshot, Timestamp*/ } from 'firebase/firestore';

import { fbFirestore, auth, /*storage */ } from "./firebase/firebaseConfig";
// import { updateCommentNameAndImage, getUserInfo, updateBio, updateLinkedin, updateInstagram, updateTwitter } from "./firebase/firebaseFunctions";


import "../src/styles/app.css";

import Layout from './pages/layout';
import Home from './pages/homepage';
import Login from './pages/login';
import Community from './pages/community';
// import UserProfile from './pages/userDetails';
import About from './pages/about';
import NotFoundPage from './pages/notFoundPage';
import Profile from './pages/profile';
import Profile2 from './pages/profile2';
import EmailForm from './pages/email';
import Register from './pages/signup';
import { AuthContextProvider } from './context/AuthContext';
import ResetP from './pages/reset';
// import Message from './pages/messaging';
import Message2 from './pages/messaging2.js';
import NotFound from './pages/notFoundPage';
import Footer from './components/footer';
import UserPosts from './pages/userPosts';
import OtherUserPosts from './pages/otherUserPosts';
import Post from './pages/post';
import AdminPanel from './pages/AdminPanel';
import Communities from './pages/communities';

import ProtectedRoutes from './protectedRoutes';
import Comingsoon from './pages/Comingsoon';
import ComingsoonSuccess from './pages/ComingsoonSuccess';

function App() {

  const location = useLocation();
  const paths = ["/admin", "/message", "/profile", "/login", "/comingsoon", "/comingsoonsuccess"]
  const shouldDisplayFooter = !paths.includes(location.pathname);
  console.log(shouldDisplayFooter);

  useEffect(() => {
    window.addEventListener('beforeunload', handleTabClosing)
    window.addEventListener('load', handleTabOpening)
    return () => {
        window.removeEventListener('beforeunload', handleTabClosing)
        window.removeEventListener('load', handleTabOpening)
    }
})

const handleTabOpening = async () => {
  setTimeout(async () => {
    if(auth.currentUser){
      console.log("Trying to update user to be online")
      try{
        const userRef = doc(fbFirestore, "users", auth.currentUser.uid);
        updateDoc(userRef, {
          isOnline: true
        });
      } catch (error){
        console.log(error)
      }
      
    } else {
      console.log("No user is signed in")
    }
  }, 1000)
}

const handleTabClosing = () => {
    if(auth.currentUser){
      console.log("Trying to update user to be offline")
      try{
        const userRef = doc(fbFirestore, "users", auth.currentUser.uid);
        updateDoc(userRef, {
          isOnline: false
        });
      } catch (error){
        console.log(error)
      }
      
    } else {
      console.log("No user is signed in")
    }
}



  return (

    <div className="App">

      <div id="AppBody">
        <div className='router-Container'>


          <AuthContextProvider>
            <Routes>

              <Route path="/" element={<Layout />}>

                <Route index element={<Comingsoon />}></Route>
                <Route path="/comingsoon" element={<Comingsoon />}></Route>
                <Route path="/comingsoonsuccess" element={<ComingsoonSuccess />}></Route>

                {/*Banned users cannot reach these routes*/}
                  <Route element={<ProtectedRoutes />}>

                    {/* <Route path="/communities" element={<Communities />}></Route>
                    <Route path="/community/:id?" element={<Community />}></Route>
                    <Route path="/community/" element={<Community />}></Route> */}

                    {/* <Route path="/messageOG" element={<Message />}></Route> */}

                    {/* <Route path="/message/:id?" element={<Message2 />}></Route> */}

                    {/* <Route path="/admin/:type?/:id?" element={<AdminPanel />}/> */}
                    {/* <Route path="/admin/" element={<AdminPanel />}/> */}
                    {/* <Route path="/profile" element={<Profile />}></Route> */}
                    {/* <Route path="/userPosts" element={<UserPosts />}></Route>
                    <Route path="/profile/:userID" element={<Profile />}></Route> */}
                  </Route>
{/* 
                   <Route path="/about" element={<About />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/notFound" element={<NotFoundPage />}></Route>
                    <Route path="/signup" element={<Register />}></Route>
                    <Route path="/reset" element={<ResetP />}></Route>
                    <Route path="/email" element={<EmailForm />}></Route>
                    <Route path="/userPosts/:userID" element={<OtherUserPosts />}></Route> */}

                    {/* <Route path="/admin/manageUsers" element={<AdminManageUsers />}></Route> */}
                    {/* <Route path="/admin/reports/:id?" element={<AdminReports />}></Route> */}

                    {/* <Route path="/posts/:id" element={<Post />}></Route> */}

                    <Route path='*' element={<NotFound />}></Route>


                    {/* <Route path="/profile/:userID" element={<Profile />}></Route>
                    <Route path="/profile2" element={<Profile2 />}></Route> */}
              </Route>
         
            </Routes>
            {
              // shouldDisplayFooter && <Footer />
            }
          </AuthContextProvider>


        </div>


      </div>
    </div>
  );
}



export default App;
