/**
 * Note: This page is not currently being used. It was created to allow users to view their own posts.
 * However, this functionality was implemented into otherUserPosts.js and this is just here in case any extra errors pop up
 * 
 * Note: Code likely deprecated
 */

import React, { useState, useEffect } from 'react';

//firebase imports
import {
  getFirstPostsByDate, getNextPostsByDate, getUserInfo, deleteComment, updateUserLikedPosts, updateUserLikedComments,
  updatePostLikes, updateReplyLikes, getReplies, addReply
} from '../firebase/firebaseFunctions';

import '../styles/blog.css'
import EditPost from '../components/community/editPost';
import { UserAuth } from '../context/AuthContext';
import Loading from '../components/loading/loading';
import Filter from 'bad-words';
import Moment from "react-moment";
import { toast, ToastContainer } from 'react-toastify';
import CreatePost from '../components/community/createPost';
import { MdContentPaste } from "react-icons/md";
import { ShareSocial } from 'react-share-social';
import Swal from 'sweetalert2';

const commentContentInputID = "comment-content-input-";
const commentFailAlertID = "comment-fail-alert-";
const commentSuccessAlertID = "comment-success-alert-";
const viewCommentsID = "comment-view-toggle-";
const postLikeBtnID = "post-like-btn-";
const postLikeIconID = "post-like-icon-";
const postLikeTextSpanID = "post-like-text-";
const domain = "https://ux-was-here.web.app";

// The number of posts loaded initially and retrieved when the "Load More Posts" button is clicked
const postsRetrieved = 5;
// The name that will be added to a comment if the commentor is not signed in
const guestUserName = "Guest";
// The default guest avatar
const guestImageURL = "https://firebasestorage.googleapis.com/v0/b/ux-was-here.appspot.com/o/profile_img%2Fguest_profile.png?alt=media&token=70f3d2b4-88a8-4968-96d5-90a81c403a40";
// The label for the like/upvote button
// The Font Awesome class names to make the thumbs up icon filled in or regular.
const filledInThumb = "fas";
const regularThumb = "far";

let filter = new Filter();

const UserPosts = () => {
  const { user } = UserAuth();
  let userData;
  let postNum = 0;
  let postLikesArray = [];
  let toggleShareBool = true;

  const voteLabel = "Upvote";
  const hasVotedLabel = "Upvoted";

  const [postsArray, setPostsArray] = useState([]);
  const [lastDoc, setLastDoc] = useState();
  const [allDocsLoaded, setAllDocsLoaded] = useState(false);
  const [likedPostsArray, setLikedPostsArray] = useState([]);
  const [likedCommentsArray, setLikedCommentsArray] = useState([]);
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    const get = async () => {
      if (user) {
        userData = await getUserInfo(user.uid);
        setUserInfo(userData);
        if (userData.data().likedPosts) {
          setLikedPostsArray(userData.data().likedPosts.filter(post => post.flagged === false));
        }
        if (userData.data().likedComments) {
          setLikedCommentsArray(userData.data().likedComments);
        }
      }
      let posts = await getFirstPostsByDate(postsRetrieved);
      posts = posts.map(post => post.data());

      // show all the posts that are not flagged
      posts = posts.filter(post => post.flagged === false);

      const last = posts[posts.length - 1];
      setPostsArray(posts);
      setLastDoc(last);
    }
    get();
  }, []);

  /**
   * Updates number of likes on relevant post.
   * @param {*} likeBtnID ID of like button
   * @param {*} likeIconID ID of like icon
   * @param {*} likeTextSpanID ID of like text
   */
  const updateLikeButtonStyleLiked = (likeBtnID, likeIconID, likeTextSpanID) => {
    document.getElementById(likeBtnID).style.fontWeight = "bolder";
    document.getElementById(likeBtnID).style.fontSize = "1rem";
    if (likeTextSpanID) {
      document.getElementById(likeTextSpanID).innerHTML = hasVotedLabel;
    } else {
      document.getElementById(likeBtnID).innerHTML += "d";
    }
    let iconElement = document.getElementById(likeIconID);
    iconElement.classList.remove(regularThumb);
    iconElement.classList.add(filledInThumb);
  }

  /**
   * Updates the styling of likes on relevant posts.
   * Liked posts will be a filled in thumbs up, otherwise it will be an outline
   * @param {*} likeBtnID ID of like button
   * @param {*} likeIconID ID of like icon
   * @param {*} likeTextSpanID ID of like text
   */
  const updateLikeButtonStyleUnliked = (likeBtnID, likeIconID, likeTextSpanID) => {
    document.getElementById(likeBtnID).style.fontWeight = "normal";
    document.getElementById(likeBtnID).style.fontSize = "0.85rem";
    if (likeTextSpanID) {
      document.getElementById(likeTextSpanID).innerHTML = voteLabel;
    } else {
      let oldHtml = document.getElementById(likeBtnID).innerHTML;
      let newHtml = oldHtml.substring(0, oldHtml.length - 1);
      document.getElementById(likeBtnID).innerHTML = newHtml;
    }
    let iconElement = document.getElementById(likeIconID);
    iconElement.classList.remove(filledInThumb);
    iconElement.classList.add(regularThumb);
  }

  /**
   * Function that updates the likes on one relevant post.
   * @param {*} postID ID of post
   * @param {*} likesArray Array of likes
   * @param {*} postNum Number of posts on page
   */
  const likeOrUnlikePost = async (postID, likesArray, postNum) => {
    const likeBtnID = postLikeBtnID + postNum;
    const likeIconID = postLikeIconID + postNum;
    const likeTextSpanID = postLikeTextSpanID + postNum;
    document.getElementById(likeBtnID).disabled = true;
    const postIDString = `${postNum}-likes`;
    if (user) {
      if (likedPostsArray && !likedPostsArray.includes(postID)) {
        likesArray[postNum]++;
        let likes = likesArray[postNum];
        let likesString = likes === 1 ? `1 ${voteLabel}` : `${likes} ${voteLabel}s`;
        await updatePostLikes(postID, true);
        await updateUserLikedPosts(user.uid, postID, true);
        likedPostsArray.push(postID);
        document.getElementById(postIDString).innerHTML = likesString;
        updateLikeButtonStyleLiked(likeBtnID, likeIconID, likeTextSpanID);
      } else {
        likesArray[postNum]--;
        let likes = likesArray[postNum];
        let likesString = likes === 1 ? `1 ${voteLabel}` : `${likes} ${voteLabel}s`;
        await updatePostLikes(postID, false);
        await updateUserLikedPosts(user.uid, postID, false);
        const postIDIndex = likedPostsArray.indexOf(postID);
        if (postIDIndex > -1) {
          likedPostsArray.splice(postIDIndex, 1);
        }
        document.getElementById(postIDString).innerHTML = likesString;
        updateLikeButtonStyleUnliked(likeBtnID, likeIconID, likeTextSpanID);
      }
    } else {
      toast.info(`You must log in to ${voteLabel} posts`);
    }
    document.getElementById(likeBtnID).disabled = false;
  }

  /**
   * Function that updates the likes on one relevant comment.
   * @param {*} postID ID of post
   * @param {*} commentID ID of comment
   * @param {*} commentLikesArray Array of comment likes
   * @param {*} postNum Number of posts on page
   * @param {*} commentNum Number of comments on page
   */
  const likeOrUnlikeComment = async (postID, commentID, commentLikesArray, postNum, commentNum) => {
    const commentLikeBtnID = `comment-${postNum}-${commentNum}`;
    document.getElementById(commentLikeBtnID).disabled = true;
    const commentIDString = `comment-${postNum}-${commentNum}-likes`;
    const likeIconID = `comment-${postNum}-${commentNum}-icon`;
    if (user) {
      if (likedCommentsArray && !likedCommentsArray.includes(commentID)) {
        commentLikesArray[commentNum]++;
        let likes = commentLikesArray[commentNum];
        let likesString = likes === 1 ? `1 ${voteLabel}` : `${likes} ${voteLabel}s`;
        await updateReplyLikes(postID, commentID, true);
        await updateUserLikedComments(user.uid, commentID, true);
        likedCommentsArray.push(commentID);
        document.getElementById(commentIDString).innerHTML = likesString;
        updateLikeButtonStyleLiked(commentLikeBtnID, likeIconID);
      } else if (likedCommentsArray) { // The user is downvoting a comment
        commentLikesArray[commentNum]--;
        let likes = commentLikesArray[commentNum];
        let likesString = likes === 1 ? `1 ${voteLabel}` : `${likes} ${voteLabel}s`;
        await updateReplyLikes(postID, commentID, false);
        await updateUserLikedComments(user.uid, commentID, false);
        const commentIDIndex = likedCommentsArray.indexOf(commentID);
        document.getElementById(commentIDString).innerHTML = likesString;
        if (commentIDIndex > -1) {
          likedCommentsArray.splice(commentIDIndex, 1);
        }
        updateLikeButtonStyleUnliked(commentLikeBtnID, likeIconID);
      }
    } else {
      toast.info(`You must log in to ${voteLabel} posts`);
    }
    document.getElementById(commentLikeBtnID).disabled = false;
  }

  /**
   * Toggle display to show or hide comments on relevant post
   * @param {*} postID ID of post
   * @param {*} postNum Number of posts on page
   */
  const toggleComments = async (postID, postNum) => {
    const postIDString = `comments-container-${postNum}`;
    const commentContainer = document.getElementById(postIDString);
    const viewCommentsIDString = viewCommentsID + postNum;
    const commentLikesArray = [];
    if (commentContainer.childNodes.length === 0) {
      let commentsData = await getReplies(postID);
      let commentCountID = `${postNum}-comments`;
      let commentText = commentsData.length === 1 ? "Comment" : "Comments"
      document.getElementById(commentCountID).innerHTML = `${commentsData.length} ${commentText}`;
      commentsData.sort((a, b) => {
        return new Date(b.data().time.seconds * 1000) - new Date(a.data().time.seconds * 1000);
      });
      let i = 0;
      commentsData.forEach((element) => {
        commentLikesArray.push(element.data().likes)
        const commentNum = i;
        let commentID = `comment-${postNum}-${commentNum}`;
        let commentLeftByUser = false;
        if (user) {
          commentLeftByUser = element.data().userID == user.uid;
        }
        commentContainer.innerHTML += commentContent(element, commentID, commentLeftByUser);
        // commentContainer.innerHTML += {CommentContent(element, commentID)};
        i++;
      });
      if (i > 0) {
        document.getElementById(viewCommentsIDString).textContent = " Hide Comments";
      }
      for (let i = 0; i < commentsData.length; i++) {
        if (likedCommentsArray && likedCommentsArray.includes(commentsData[i].id)) {
          const commentLikeBtnID = `comment-${postNum}-${i}`;
          const commentLikeIconID = `comment-${postNum}-${i}-icon`;
          const commentLikeBtnElement = document.getElementById(commentLikeBtnID);
          if (commentLikeBtnElement) {
            commentLikeBtnElement.style.fontWeight = "bolder";
            commentLikeBtnElement.style.fontSize = "1rem";
            commentLikeBtnElement.innerHTML += "d";
            const commentLikeIconElement = document.getElementById(commentLikeIconID);
            commentLikeIconElement.classList.remove(regularThumb);
            commentLikeIconElement.classList.add(filledInThumb);
          }
        }
        let commentID = `comment-${postNum}-${i}`;
        document.getElementById(commentID).addEventListener("click", () => {
          likeOrUnlikeComment(postID, commentsData[i].id, commentLikesArray, postNum, i);
        });
        if (user && user.uid == commentsData[i].data().userID) {
          let deleteCommentBtnID = commentID + "-delete-btn";
          let deleteBtnElement = document.getElementById(deleteCommentBtnID);
          if (deleteBtnElement) {
            deleteBtnElement.addEventListener("click", () => {
              deleteUserComment(commentsData[i].id, deleteCommentBtnID, postID, postNum);
            });
          }
        }
      }
    } else {
      document.getElementById(viewCommentsIDString).textContent = " View Comments";
      commentContainer.innerHTML = "";
    }
  }

  /**
   * Delete the comment with the given ID from the database. Decrement the comment count for the post.
   * @param {*} commentID String. The ID of the comment to be deleted
   * @param {*} deleteCommentBtnID String. The ID of the delete button element
   * @param {*} postID String. The ID of the parent post
   * @param {*} postNum Integer. Where the post appears in the list of posts (First post has postNum = 0)
   */
  const deleteUserComment = (commentID, deleteCommentBtnID, postID, postNum) => {
    document.getElementById(deleteCommentBtnID).disabled = true;
    Swal.fire({
      title: "Delete Comment?",
      showCancelButton: true,
      confirmButtonColor: '#10bf59',
      cancelButtonColor: '#808080',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteComment(postID, commentID);
        let commentContainerID = `comments-container-${postNum}`;
        let commentContainer = document.getElementById(commentContainerID);
        if (commentContainer) {
          commentContainer.innerHTML = "";
          toggleComments(postID, postNum);
        }
      } else {
        document.getElementById(deleteCommentBtnID).disabled = false;
      }
    })
  }

  /**
   * Handles the content of comment user would like to upload on relevant post.
   * @param {*} element Time from post from database
   * @param {*} commentID ID for comment
   * @param {*} commentLeftByUser Text content user would like to comment
   * @returns 
   */
  const commentContent = (element, commentID, commentLeftByUser) => {
    let seconds = element.data().time.seconds;
    let date = new Date(seconds * 1000);
    let commentLikesID = commentID + "-likes";
    let commentIconID = commentID + "-icon";
    let commentHTML = `
    <div class="comment-container">
      ${commentLeftByUser ? `
      <div class="delete-comment-btn">
        <button id=${commentID + "-delete-btn"} class="borderless-btn"><i class="fas fa-backspace fa-fw fa-lg m-r-3"></i></button>
      </div>`
        : ""}
      <div class='postAuthorContainer'>
        <img class='postAuthorImage' src=${element.data().image} alt="author photo" />
          <div class='commentAuthorDetails'>
            <h2 class='commentUser'>${element.data().name}</h2>
            <h6 class="comment-datePosted">
                  <Moment fromNow>${date.toDateString()}</Moment>
            </h6>
          </div>
        </div>   
      <div class="timeline-content">
        <div class='commentContent'>
          ${element.data().content}
        </div>
      </div>
      <div class="comment-likes">
         <div class="comment-footer">
         <button href="javascript:;" class="m-r-15 text-inverse-lighter borderless-btn" id=${commentID}><i id=${commentIconID} class="${regularThumb} fa-thumbs-up fa-fw fa-lg m-r-3"></i> ${voteLabel}</button>
       </div>
        <div class="comment-stats-right">
          <span class="stats-text" id=${commentLikesID}>${element.data().likes} ${voteLabel}${element.data().likes === 1 ? "" : "s"}</span>
        </div>
      </div>

    </div>
    `
    return commentHTML;
  }

  /**
   * Open or close a form for submitting comments on the given post
   * @param {*} postNum Int. The number of the post for which the comment box will be toggled
   * @param {*} setOpen Boolean. True to open the comment box, false to close
   */
  const toggleCommentBox = (postNum, setOpen) => {
    const commentBoxID = `comment-box-${postNum}`;
    const failAlert = commentFailAlertID + postNum;
    const successAlert = commentSuccessAlertID + postNum;
    if (setOpen) {
      document.getElementById(commentBoxID).style.display = "inline";
    } else {
      document.getElementById(successAlert).style.display = "none";
      document.getElementById(failAlert).style.display = "none";
      document.getElementById(commentBoxID).style.display = "none";
    }
  }

  /**
   * Onclick handler for commenting, submits comments from user to firestore
   * @param {*} postNum Number of posts on page
   * @param {*} postID ID of post
   */
  const submitComment = async (postNum, postID) => {
    const contentID = commentContentInputID + postNum;
    let content = document.getElementById(contentID).value;
    if (content.trim() === "") {
      toast.info("Please type a comment before posting");
    } else {
      content = filter.clean(content);
      let name = guestUserName;
      let image = guestImageURL;
      let userUID = ""
      if (user) {
        name = userInfo.data().displayName
        image = user.photoURL;
        userUID = user.uid;
      }

      await addReply(postID, name, content, image, userUID);
      document.getElementById(contentID).value = "";
      let commentContainerID = `comments-container-${postNum}`;
      let commentContainer = document.getElementById(commentContainerID);
      if (commentContainer) {
        commentContainer.innerHTML = "";
        toggleComments(postID, postNum);
      }
      // Swal.fire({
      //   title: "Post Comment?",
      //   text: content,
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      // }).then(async (result) => {
      //   if (result.isConfirmed) {
      //     await addReply(postID, name, content, image, userUID);
      //     document.getElementById(contentID).value = "";
      //     let commentContainerID = `comments-container-${postNum}`;
      //     let commentContainer = document.getElementById(commentContainerID);
      //     if (commentContainer) {
      //       commentContainer.innerHTML = "";
      //       toggleComments(postID, postNum);
      //     }
      //   }
      // })
    }
  }

  /**
   * Function that fetches more user posts from firestore if number of posts is greater than 5.
   */
  const fetchMore = async () => {
    let nextPosts = await getNextPostsByDate(lastDoc, postsRetrieved);
    if (nextPosts.length < postsRetrieved) {
      setAllDocsLoaded(true);
    }
    if (nextPosts.length !== 0) {
      const last = nextPosts[nextPosts.length - 1];
      setPostsArray((postsArray) => [...postsArray, ...nextPosts]);
      setLastDoc(last);
    }
  }

  /**
   * Display toggle for showing and hiding the share section for relevant posts.
   * @param {*} currentPostNum 
   */
  const toggleShare = (currentPostNum) => {
    if (toggleShareBool) {
      document.getElementById("shareS" + currentPostNum).style.display = "block";
      toggleShareBool = !toggleShareBool;
    }
    else {
      document.getElementById("shareS" + currentPostNum).style.display = "none";
      toggleShareBool = !toggleShareBool;
    }
  }


  if (postsArray.length === 0) {
    return <Loading />
  }

  let existingPosts = false;
  let otherUser = false;

  /**
   * Load page depending on user auth
   */
  const createPostHtml = (data, key) => {
    // let posts = getPosts();
    var parser = new DOMParser();
    const currentPostNum = postNum;
    const postIsLiked = likedPostsArray.includes(data.id);
    const likeButtonStyle = {
      fontWeight: postIsLiked ? "bolder" : "normal",
      fontSize: postIsLiked ? "1rem" : "0.85rem"
    }
    const likeButtonIconType = postIsLiked ? filledInThumb : regularThumb;
    postLikesArray.push(data.likes);
    postNum++;

    let contentData = data.content;
    const paragraphs = [];
    const contentDataWithLines = contentData.split('\n');

    contentDataWithLines.forEach(element => {
      paragraphs.push(<h5 className='postContentData'><div dangerouslySetInnerHTML={{__html: element}}></div></h5>)
      // paragraphs.push(<h5 className='postContentData'>{element}</h5>)
      // paragraphs.push(parser.parseFromString(element, "text/html"))
      //paragraphs.push(<h5 className='postContentData'>{element}</h5>)
      
    });

    if (user.uid === data.author_uid) {
      existingPosts = true;
      return (
        <div className='postContainer' key={key}>
          <div className="postCard-userPost">
            <div className="timeline-header">
              <div>
                <EditPost user={user} userPostData={data} postNum={postNum}></EditPost>
              </div>
              <div className='postAuthorContainer'>
                <img className='authorImage' src={data.author_photoURL} alt="" />
                <div>
                  <h2 className='username'>{data.author_name}</h2>
                  <h6 className="datePosted">
                    <Moment fromNow>{data.time.toDate()}</Moment>
                  </h6>
                </div>
              </div>
            </div>
            <div className="timeline-content">
              <div>
                <h1 className='postTitle'>{data.title}</h1>
              </div>
              <div className='postContent'>
                <h5 className='postContentData'>{paragraphs}</h5>
              </div>
              <div className='postImage'>
                {
                  (data.image !== "") ? <img src={data.image} /> : ""
                }
              </div>
            </div>
            <div className="timeline-likes">
              <div className="stats-right">
                {/* Add comments and shares */}
                <span className="stats-text" id={currentPostNum + "-likes"}>{data.data().likes} {voteLabel}{data.data().likes === 1 ? "" : "s"}</span>
                <span className="stats-text" id={currentPostNum + "-comments"}>{data.data().comments} Comment{data.data().comments === 1 ? "" : "s"}</span>
              </div>
            </div>
            <div className="timeline-footer">
              <button href="javascript:;" id={postLikeBtnID + currentPostNum} style={likeButtonStyle} className="m-r-15 text-inverse-lighter borderless-btn"
                onClick={async () => { likeOrUnlikePost(data.id, postLikesArray, currentPostNum) }}>
                <i id={postLikeIconID + currentPostNum} className={likeButtonIconType + " fa-thumbs-up fa-fw fa-lg m-r-3"}></i>
                <span id={postLikeTextSpanID + currentPostNum} className='upvoteText'>{postIsLiked ? hasVotedLabel : voteLabel} </span>
              </button>
              <a href="javascript:;" className="m-r-15 text-inverse-lighter" onClick={() => toggleComments(data.id, currentPostNum)}>
                <i className="fa fa-comments fa-fw fa-lg m-r-3"></i>
                <span className="view-comments-span" id={viewCommentsID + currentPostNum}> View Comments</span>
              </a>
              <a href="javascript:;" id={"shareLink" + currentPostNum} className="m-r-15 text-inverse-lighter" onClick={() => toggleShare(currentPostNum)}>
                <i className="fa fa-share fa-fw fa-lg m-r-3"></i>
                <a href="javascript:;" className='shareText' >Share</a>
              </a>
              <div id={"shareS" + currentPostNum} style={{ display: "none" }}>
                <ShareSocial
                  title={'Check out my post called "' + data.title + '" on UX Was Here: '}
                  url={domain + "/posts/" + data.post_ID}
                  socialTypes={['facebook', 'twitter', 'linkedin']} />
              </div>
            </div>
            <div id={`comments-container-${currentPostNum}`}>

            </div>
            <div className="timeline-comment-box">
              <div className="input">
                <form action="">
                  <div className="input-group create-comment-btn">
                    <textarea id={commentContentInputID + currentPostNum} type="textarea" rows="1" className="form-control rounded-corner comment-text-area" placeholder="Write a comment..." />
                    <button className="btn communityBtn commentBtn btn-primary f-s-12 rounded-corner" onClick={() => { submitComment(currentPostNum, data.id) }} type="button">Post Comment</button>
                  </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      )
    }


  }

  // Represents display that will be loaded for users who have no posts in database
  let emptyBody =
    <div className='emptyPost'>
      <div className='postIcon'>
        <h1><MdContentPaste /></h1>
      </div>
      <h2> Create Posts </h2>
      <h6> When you create posts, they will appear on this page. </h6>

      <div>
        <button type="button" className="registeredUserButton communityBtn btn btn-primary" data-bs-toggle="modal" data-bs-target="#createModal">
          Create a post
        </button>
        <CreatePost />
      </div>
    </div>

  // Component return
  return (
    <div className='communityContainer'>
      <div className='blogContainer'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div id="content" className="content content-full-width">
                <div className="profile-content">
                  <div className="tab-content p-0">
                    {postsArray.map((data, key) => createPostHtml(data, key))}
                    {/* Display empty body if no user posts fetched */}
                    {existingPosts ? (null) : (emptyBody)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='load-btn-div'>
        {/* {!allDocsLoaded && <button className='communityBtn btn btn-primary load-btn' onClick={fetchMore}>Load More Posts</button>} */}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </div>
  )
}

export default UserPosts;