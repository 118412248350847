import React from 'react'
import { Outlet } from 'react-router-dom'
import Login from './pages/login'

import { auth, fbFirestore } from "./firebase/firebaseConfig";
import { getUserInfo, updateRole } from './firebase/firebaseFunctions';
import BanPage from './pages/banPage';

async function checkIfBanned() {
  if (auth !== null && auth.currentUser !== null) {
    if (auth.currentUser.uid) {
      const userData = await getUserInfo(auth.currentUser.uid);
      return userData.data().isBanned;
    }
  }
  return false;
}

async function checkIfNotLoggedIn () {
  if (auth === null || auth.currentUser === null) {
   return true;
  }
}


// This is a protected route that checks if the user is banned or not, if they are, they cannot access the routes under this component
export default function ProtectedRoutes() {

  const [IsLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isBanned, setIsBanned] = React.useState(false);

  const useEffect = React.useEffect;

  useEffect(async () => {
    const isBanned = await checkIfBanned();
    setIsBanned(isBanned);

    const isLoggedIn = await checkIfNotLoggedIn();
    setIsLoggedIn(isLoggedIn);

  }, []);

  return (
    IsLoggedIn ? <Login /> : 
    isBanned ? <BanPage /> : <Outlet />
  )
}
