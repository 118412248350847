import "../../styles/comingSoon.scss"

function ComingSoonFormSuccess() {

  return (
    <>
    <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: "1rem"}}>
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M50 0C22.4 0 0 22.4 0 50C0 77.6 22.4 100 50 100C77.6 100 100 77.6 100 50C100 22.4 77.6 0 50 0ZM40 75L15 50L22.05 42.95L40 60.85L77.95 22.9L85 30L40 75Z" fill="#36BF36"/>
</svg>
    </div>
<div id='signupThanks' style={{width: "100%", display: "flex", justifyContent: "center", marginTop: "1rem", flexDirection: "column", alignItems: "center"}}>
  <h4 className="dm-sans" style={{width: "80%", marginTop: "1rem"}}>Woo-hoo! Thanks for joining our super secret</h4>
  <h4 className="dm-sans" style={{width: "80%", marginTop: "0"}}>club of early birds.</h4>
</div>
    </>
  )
}

export default ComingSoonFormSuccess