// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD-a0YaelXh5miv8zFXZxrAjsT4sYLg9oM",
  authDomain: "ux-was-here-dev.firebaseapp.com",
  databaseURL: "https://ux-was-here-dev-default-rtdb.firebaseio.com",
  projectId: "ux-was-here-dev",
  storageBucket: "ux-was-here-dev.appspot.com",
  messagingSenderId: "532178740555",
  appId: "1:532178740555:web:63a285f00aa2ba8db034ce",
  measurementId: "G-ETDGNX9LSZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const fbFirestore = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const fbRealtime = getDatabase(app);

export { fbFirestore, auth, storage, fbRealtime };