import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { useLocation } from 'react-router-dom';

const Layout = () => {

    const location = useLocation();

    return (
        <div>
         {
            // !location.pathname.includes("/comingsoon") && <Navbar/>
         }
        <Outlet/>
        {/* <Footer/> */}
         </div> 
    );
};

export default Layout;