import React, {Component} from 'react';
import Lottie from 'react-lottie';
import loadingAnimation from '../lotties/load.json';

import '../../styles/homepage.css';

class LoadingLottie extends Component {
    render() {
        const defaultOptions = {
          loop: true,
          autoplay: true,
          animationData: loadingAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        };

        return (
            <div className='imageFeature'>
              <Lottie options={defaultOptions}/>
              </div>
          );
        }
}

export default LoadingLottie;