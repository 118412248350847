/**
 * 
 * Note: this code is likely deprecated, but leaving it here just in case things break
 */


import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import {
  getFirstPostsByDate, getUserInfo, updateUserLikedPosts, updateUserLikedComments,
  updatePostLikes, updateReplyLikes, getReplies, addReply, deleteComment, getPostInfo
} from '../firebase/firebaseFunctions';
import '../styles/blog.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserAuth } from '../context/AuthContext';
import { ShareSocial } from 'react-share-social'
import Filter from 'bad-words';


import Swal from 'sweetalert2';
import Moment from "react-moment";

const postLikeIconID = "post-like-icon-";
const postLikeTextSpanID = "post-like-text-";
const commentContentInputID = "comment-content-input-";
const commentFailAlertID = "comment-fail-alert-";
const commentSuccessAlertID = "comment-success-alert-";
const viewCommentsID = "comment-view-toggle-";
const postLikeBtnID = "post-like-btn-";
const domain = "https://ux-was-here.web.app";

let filter = new Filter();

// The name that will be added to a comment if the commentor is not signed in
const guestUserName = "Guest";
// The default guest avatar
const guestImageURL = "https://firebasestorage.googleapis.com/v0/b/ux-was-here.appspot.com/o/profile_img%2Fguest_profile.png?alt=media&token=70f3d2b4-88a8-4968-96d5-90a81c403a40";
// The label for the like/upvote button
const voteLabel = "Upvote";
const hasVotedLabel = "Upvoted";

// The Font Awesome class names to make the thumbs up icon filled in or regular.
const filledInThumb = "fas";
const regularThumb = "far";

// Function that returns the single post page
const Post = () => {
  const navigate = useNavigate();

  const { user } = UserAuth();

  let userData;
  let toggleShareBool = true;
  let postNum = 0;
  let postLikesArray = [];


  let { id } = useParams();

  const postsRetrieved = 1;

  const [postsArray, setPostsArray] = useState([]);
  const [lastDoc, setLastDoc] = useState();
  const [allDocsLoaded, setAllDocsLoaded] = useState(false);
  const [likedPostsArray, setLikedPostsArray] = useState([]);
  const [likedCommentsArray, setLikedCommentsArray] = useState([]);
  const [userInfo, setUserInfo] = useState();


  useEffect(() => {
    const get = async () => {
      if (user) {
        userData = await getUserInfo(user.uid);
        setUserInfo(userData);
        if (userData.data().likedPosts) {
          setLikedPostsArray(userData.data().likedPosts);
        }
        if (userData.data().likedComments) {
          setLikedCommentsArray(userData.data().likedComments);
        }
      }
      
      const postId = window.location.href.split("/").pop();
      const post = await getPostInfo(postId);
      setPostsArray(post);

      const last = post;

      setLastDoc(last);
    }

    get();
  }, []);

  // Function that handles update of the style of the button
  const updateLikeButtonStyleLiked = (likeBtnID, likeIconID, likeTextSpanID) => {
    document.getElementById(likeBtnID).style.fontWeight = "bolder";
    document.getElementById(likeBtnID).style.fontSize = "1rem";
    if (likeTextSpanID) {
      document.getElementById(likeTextSpanID).innerHTML = hasVotedLabel;
    } else {
      document.getElementById(likeBtnID).innerHTML += "d";
    }
    let iconElement = document.getElementById(likeIconID);
    iconElement.classList.remove(regularThumb);
    iconElement.classList.add(filledInThumb);
  }


  // Function that handles update of the style of the button
  const updateLikeButtonStyleUnliked = (likeBtnID, likeIconID, likeTextSpanID) => {
    document.getElementById(likeBtnID).style.fontWeight = "normal";
    document.getElementById(likeBtnID).style.fontSize = "0.85rem";
    if (likeTextSpanID) {
      document.getElementById(likeTextSpanID).innerHTML = voteLabel;
    } else {
      let oldHtml = document.getElementById(likeBtnID).innerHTML;
      let newHtml = oldHtml.substring(0, oldHtml.length - 1);
      document.getElementById(likeBtnID).innerHTML = newHtml;
    }
    let iconElement = document.getElementById(likeIconID);
    iconElement.classList.remove(filledInThumb);
    iconElement.classList.add(regularThumb);
  }


  // Function that handles liking/unliking of the post
  const likeOrUnlikePost = async (postID, likesArray, postNum) => {
    const likeBtnID = postLikeBtnID + postNum;
    const likeIconID = postLikeIconID + postNum;
    const likeTextSpanID = postLikeTextSpanID + postNum;
    document.getElementById(likeBtnID).disabled = true;
    const postIDString = `${postNum}-likes`;
    if (user) {
      if (likedPostsArray && !likedPostsArray.includes(postID)) {
        likesArray[postNum]++;
        let likes = likesArray[postNum];
        let likesString = likes == 1 ? `1 ${voteLabel}` : `${likes} ${voteLabel}s`;
        await updatePostLikes(postID, true);
        await updateUserLikedPosts(user.uid, postID, true);
        likedPostsArray.push(postID);
        document.getElementById(postIDString).innerHTML = likesString;
        updateLikeButtonStyleLiked(likeBtnID, likeIconID, likeTextSpanID);
      } else {
        likesArray[postNum]--;
        let likes = likesArray[postNum];
        let likesString = likes == 1 ? `1 ${voteLabel}` : `${likes} ${voteLabel}s`;
        await updatePostLikes(postID, false);
        await updateUserLikedPosts(user.uid, postID, false);
        const postIDIndex = likedPostsArray.indexOf(postID);
        if (postIDIndex > -1) {
          likedPostsArray.splice(postIDIndex, 1);
        }
        document.getElementById(postIDString).innerHTML = likesString;
        updateLikeButtonStyleUnliked(likeBtnID, likeIconID, likeTextSpanID);
      }
    } else {
      toast.info(`You must log in to ${voteLabel} posts`);
    }
    document.getElementById(likeBtnID).disabled = false;
  }

  // Function that handles liking/unliking of the comment
  const likeOrUnlikeComment = async (postID, commentID, commentLikesArray, postNum, commentNum) => {
    const commentLikeBtnID = `comment-${postNum}-${commentNum}`;
    document.getElementById(commentLikeBtnID).disabled = true;
    const commentIDString = `comment-${postNum}-${commentNum}-likes`;
    const likeIconID = `comment-${postNum}-${commentNum}-icon`;
    if (user) {
      if (likedCommentsArray && !likedCommentsArray.includes(commentID)) { // The user is upvoting a comment
        commentLikesArray[commentNum]++;
        let likes = commentLikesArray[commentNum];
        let likesString = likes == 1 ? `1 ${voteLabel}` : `${likes} ${voteLabel}s`;
        await updateReplyLikes(postID, commentID, true);
        await updateUserLikedComments(user.uid, commentID, true);
        likedCommentsArray.push(commentID);
        document.getElementById(commentIDString).innerHTML = likesString;
        updateLikeButtonStyleLiked(commentLikeBtnID, likeIconID);
      } else if (likedCommentsArray) { // The user is downvoting a comment
        commentLikesArray[commentNum]--;
        let likes = commentLikesArray[commentNum];
        let likesString = likes == 1 ? `1 ${voteLabel}` : `${likes} ${voteLabel}s`;
        await updateReplyLikes(postID, commentID, false);
        await updateUserLikedComments(user.uid, commentID, false);
        const commentIDIndex = likedCommentsArray.indexOf(commentID);
        document.getElementById(commentIDString).innerHTML = likesString;
        if (commentIDIndex > -1) {
          likedCommentsArray.splice(commentIDIndex, 1);
        }
        updateLikeButtonStyleUnliked(commentLikeBtnID, likeIconID);
      }
    } else {
      toast.info(`You must log in to ${voteLabel} posts`);
    }
    document.getElementById(commentLikeBtnID).disabled = false;
  }

  // Function that handles comment toggle
  const toggleComments = async (postID, postNum) => {
    const postIDString = `comments-container-${postNum}`;
    const commentContainer = document.getElementById(postIDString);
    const viewCommentsIDString = viewCommentsID + postNum;
    const commentLikesArray = [];
    if (commentContainer.childNodes.length == 0) {
      let commentsData = await getReplies(postID);
      let commentCountID = `${postNum}-comments`;
      let commentText = commentsData.length == 1 ? "Comment" : "Comments"
      document.getElementById(commentCountID).innerHTML = `${commentsData.length} ${commentText}`;
      commentsData.sort((a, b) => {
        return new Date(b.data().time.seconds * 1000) - new Date(a.data().time.seconds * 1000);
      });
      let i = 0;
      commentsData.forEach((element) => {
        commentLikesArray.push(element.data().likes)
        const commentNum = i;
        let commentID = `comment-${postNum}-${commentNum}`;
        let commentLeftByUser = false;
        if (user) {
          commentLeftByUser = element.data().userID == user.uid;
        }
        commentContainer.innerHTML += commentContent(element, commentID, commentLeftByUser);
        i++;
      });
      if (i > 0) {
        document.getElementById(viewCommentsIDString).textContent = " Hide Comments";
      }
      for (let i = 0; i < commentsData.length; i++) {
        if (likedCommentsArray && likedCommentsArray.includes(commentsData[i].id)) {
          const commentLikeBtnID = `comment-${postNum}-${i}`;
          const commentLikeIconID = `comment-${postNum}-${i}-icon`;
          const commentLikeBtnElement = document.getElementById(commentLikeBtnID);
          if (commentLikeBtnElement) {
            commentLikeBtnElement.style.fontWeight = "bolder";
            commentLikeBtnElement.style.fontSize = "1rem";
            commentLikeBtnElement.innerHTML += "d";
            const commentLikeIconElement = document.getElementById(commentLikeIconID);
            commentLikeIconElement.classList.remove(regularThumb);
            commentLikeIconElement.classList.add(filledInThumb);
          }
        }
        let commentID = `comment-${postNum}-${i}`;
        document.getElementById(commentID).addEventListener("click", () => {
          likeOrUnlikeComment(postID, commentsData[i].id, commentLikesArray, postNum, i);
        });
        if (user && user.uid == commentsData[i].data().userID) {
          let deleteCommentBtnID = commentID + "-delete-btn";
          let deleteBtnElement = document.getElementById(deleteCommentBtnID);
          if (deleteBtnElement) {
            deleteBtnElement.addEventListener("click", () => {
              deleteUserComment(commentsData[i].id, deleteCommentBtnID, postID, postNum);
            });
          }
        }
      }
    } else {
      document.getElementById(viewCommentsIDString).textContent = " View Comments";
      commentContainer.innerHTML = "";
    }
  }


  // Function that handles deleting a comment
  // TODO: currently broken
  const deleteUserComment = (commentID, deleteCommentBtnID, postID, postNum) => {
    document.getElementById(deleteCommentBtnID).disabled = true;
    Swal.fire({
      title: "Delete Comment?",
      showCancelButton: true,
      confirmButtonColor: '#10bf59',
      cancelButtonColor: '#808080',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteComment(postID, commentID);
        let commentContainerID = `comments-container-${postNum}`;
        let commentContainer = document.getElementById(commentContainerID);
        if (commentContainer) {
          commentContainer.innerHTML = "";
          toggleComments(postID, postNum);
        }
      } else {
        document.getElementById(deleteCommentBtnID).disabled = false;
      }
    })
  }

  // Function that handles adding a comment to the post
  const commentContent = (element, commentID, commentLeftByUser) => {
    let seconds = element.data().time.seconds;
    let date = new Date(seconds * 1000);
    let commentLikesID = commentID + "-likes";
    let commentIconID = commentID + "-icon";
    let commentHTML = `
  <div class="comment-container">
    ${commentLeftByUser ? `
    <div class="delete-comment-btn">
      <button id=${commentID + "-delete-btn"} class="borderless-btn"><i class="fas fa-backspace fa-fw fa-lg m-r-3"></i></button>
    </div>`
        : ""}
    <div class='postAuthorContainer'>
      <img class='postAuthorImage' src=${element.data().image} alt="author photo" />
        <div class='commentAuthorDetails'>
          <h2 class='commentUser'>${element.data().name}</h2>
          <h6 class="comment-datePosted">
                <Moment fromNow>${date.toDateString()}</Moment>
          </h6>
        </div>
      </div>   
    <div class="timeline-content">
      <div class='commentContent'>
        ${element.data().content}
      </div>
    </div>
    <div class="comment-likes">
       <div class="comment-footer">
       <button href="javascript:;" class="m-r-15 text-inverse-lighter borderless-btn" id=${commentID}><i id=${commentIconID} class="${regularThumb} fa-thumbs-up fa-fw fa-lg m-r-3"></i> ${voteLabel}</button>
     </div>
      <div class="comment-stats-right">
        <span class="stats-text" id=${commentLikesID}>${element.data().likes} ${voteLabel}${element.data().likes == 1 ? "" : "s"}</span>
      </div>
    </div>

  </div>
  `
    return commentHTML;
  }

  /**
   * Open or close a form for submitting comments on the given post
   * @param {*} postNum Int. The number of the post for which the comment box will be toggled
   * @param {*} setOpen Boolean. True to open the comment box, false to close
   */
  const toggleCommentBox = (postNum, setOpen) => {
    const commentBoxID = `comment-box-${postNum}`;
    const failAlert = commentFailAlertID + postNum;
    const successAlert = commentSuccessAlertID + postNum;
    if (setOpen) {
      document.getElementById(commentBoxID).style.display = "inline";
    } else {
      document.getElementById(successAlert).style.display = "none";
      document.getElementById(failAlert).style.display = "none";
      document.getElementById(commentBoxID).style.display = "none";
    }
  }

  const toggleShare = (currentPostNum) => {

    if (toggleShareBool) {
      document.getElementById("shareS" + currentPostNum).style.display = "block";
      toggleShareBool = !toggleShareBool;
    }
    else {
      document.getElementById("shareS" + currentPostNum).style.display = "none";
      toggleShareBool = !toggleShareBool;
    }
  }


  // Function that handles comment submission
  const submitComment = async (postNum, postID) => {
    const contentID = commentContentInputID + postNum;
    let content = document.getElementById(contentID).value;
    if (content.trim() === "") {
      toast.info("Please type a comment before posting");
    } else {
      content = filter.clean(content);
      let name = guestUserName;
      let image = guestImageURL;
      let userUID = ""
      if (user) {
        name = userInfo.data().displayName
        image = userInfo.data().photoURL;
        userUID = user.uid;
      }
      Swal.fire({
        title: "Post Comment?",
        text: content,
        showCancelButton: true,
        confirmButtonColor: '#10bf59',
        cancelButtonColor: '#808080',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await addReply(postID, name, content, image, userUID);
          document.getElementById(contentID).value = "";
          let commentContainerID = `comments-container-${postNum}`;
          let commentContainer = document.getElementById(commentContainerID);
          if (commentContainer) {
            commentContainer.innerHTML = "";
            toggleComments(postID, postNum);
          }
        }
      })
    }
  }


  // Function that handles creation of HTML code for the post
  function createPostHtml (data, key) {
    var parser = new DOMParser();
    let seconds = data.data().time.seconds;
    let date = new Date(seconds * 1000);
    const currentPostNum = postNum;
    const postIsLiked = likedPostsArray.includes(data.id);
    const likeButtonStyle = {
      fontWeight: postIsLiked ? "bolder" : "normal",
      fontSize: postIsLiked ? "1rem" : "0.85rem"
    }
    const likeButtonIconType = postIsLiked ? filledInThumb : regularThumb;

    let contentData = data.data().content;
    const paragraphs = [];
    const contentDataWithLines = contentData.split('\n');

    contentDataWithLines.forEach(element => {
      // paragraphs.push(parser.parseFromString(element, "text/html")) // supposed to be rich text support but this is broken
      paragraphs.push(<h5 className='postContentData'>{element}</h5>)
    });

    postLikesArray.push(data.data().likes);
    postNum++;
    return (
      <div className='postContainer' key={key}>
        <div className="postCard">
          <div className="timeline-header">
            <div className='postAuthorContainer'>
              <img className='postAuthorImage' src={data.data().author_photoURL} alt="" />
              <div className='authorDetails'>
                <h2 className='username'>{data.data().author_name}</h2>
                <h6 className="datePosted">
                  <Moment fromNow>{data.data().time.toDate()}</Moment>
                </h6>
              </div>
            </div>
            {/* <span className="pull-right text-muted stats-text">0 Views</span> */}
          </div>
          <div className="timeline-content">
            <div>
              <h1 className='postTitle'>{data.data().title}</h1>
            </div>
            <div className='postContent'>
              {/* <h5 className='postContentData'>{data.data().content}</h5> */}
              {paragraphs}
            </div>
            <div className='postImage'>
              {
                (data.data().image !== "") ? <img src={data.data().image} /> : ""
              }
            </div>
          </div>
          <div className="timeline-likes">
            <div className="stats-right">
              {/* Add comments and shares */}
              <span className="stats-text" id={currentPostNum + "-likes"}>{data.data().likes} {voteLabel}{data.data().likes == 1 ? "" : "s"}</span>
              <span className="stats-text" id={currentPostNum + "-comments"}>{data.data().comments} Comment{data.data().comments == 1 ? "" : "s"}</span>
            </div>
          </div>
          <div className="timeline-footer">
            <button href="javascript:;" id={postLikeBtnID + currentPostNum} style={likeButtonStyle} className="m-r-15 text-inverse-lighter borderless-btn"
              onClick={async () => { likeOrUnlikePost(data.id, postLikesArray, currentPostNum) }}>
              <i id={postLikeIconID + currentPostNum} className={likeButtonIconType + " fa-thumbs-up fa-fw fa-lg m-r-3"}></i>
              <span id={postLikeTextSpanID + currentPostNum} className='upvoteText'>{postIsLiked ? hasVotedLabel : voteLabel} </span>
            </button>
            <a href="javascript:;" className="m-r-15 text-inverse-lighter" onClick={() => toggleComments(data.id, currentPostNum)}>
              <i className="fa fa-comments fa-fw fa-lg m-r-3"></i>
              <span className="view-comments-span" id={viewCommentsID + currentPostNum}> View Comments</span>
            </a>
            <a href="javascript:;" id={"shareLink" + currentPostNum} className="m-r-15 text-inverse-lighter" onClick={() => toggleShare(currentPostNum)}>
              <i className="fa fa-share fa-fw fa-lg m-r-3"></i>
              <a href="javascript:;" className='shareText' >Share</a>
            </a>
            <div id={"shareS" + currentPostNum} style={{ display: "none" }}>
              <ShareSocial
                title={'Check out this post called "' + data.data().title + '" on UX Was Here: '}
                url={domain + "/posts/" + data.data().post_ID}
                socialTypes={['facebook', 'twitter', 'linkedin']} />
            </div>
          </div>
          <div id={`comments-container-${currentPostNum}`}>

          </div>
          <div className="timeline-comment-box">
            <div className="input">
              <form action="">
                <div className="input-group create-comment-btn">
                  <textarea id={commentContentInputID + currentPostNum} type="textarea" rows="1" className="form-control rounded-corner comment-text-area" placeholder="Write a comment..." />
                  <button className="btn communityBtn commentBtn btn-primary f-s-12 rounded-corner" onClick={() => { submitComment(currentPostNum, data.id) }} type="button">Post Comment</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='communityContainer'>
      <div className='blogContainer'>
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <div id="content" className="content content-full-width">
                <div className="profile-content">
                  <div className="tab-content p-0">
                    <div className="tab-pane fade active show" id="profile-post">
                      {postsArray.map((data, key) => createPostHtml(data, key))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </div>
  )

}

export default Post;
