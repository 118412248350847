function ComingSoonForm({id}) {

  //Extra input to prevent bots
  return (
    <form id={id} action="https://uxwashere.us9.list-manage.com/subscribe/post?u=f09e957a2a760370eae3723a6&amp;id=89f99d776c&amp;f_id=004c79e1f0" method="post" target="_self">    
        <div aria-hidden="true" style={{position: "absolute", left: "-5000px"}}>
    <input type="text" name="b_f09e957a2a760370eae3723a6_89f99d776c" tabindex="-1" value=""></input>
    </div>
    <div id={`signup${id}InputContainer`}>
    <input id={`signup${id}Input`} className="dm-sans" type="email" name="EMAIL" placeholder="Enter your email" required></input>
    <button type="submit" className={`signup${id}Button dm-sans`} name="subscribe" value="Subscribe">Sign up</button>
    </div>
</form>
  )
}

export default ComingSoonForm