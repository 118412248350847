import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../styles/blog.css'
import { updatePostReports, updateCommentReports, hasUserReportedPost, hasUserReportedComment, addNotification } from '../../firebase/firebaseFunctions';
import "../../styles/post/report.css";
import { getPost, getUsers } from '../../firebase/firebaseFunctions';

// import toast for displaying messages to the user
import { toast } from 'react-toastify';
import { CarouselItem, ListGroupItem } from 'react-bootstrap';
import { ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';

// TODO: would be better to move these to a separate text file and import them
const reportMap = new Map();
reportMap.set("Nudity", 
["Adult Nudity", "Sexual Activity", "Sexual exploitation", "Sharing private images", "Other"]);
reportMap.set("Harassment", 
["Hate speech", "Threats", "Cyberbullying", "Other"]);
reportMap.set("Violence", 
["Self-harm", "Harm to others", "Animal abuse", "Other"]);
reportMap.set("Spam",
["Promoting a product or service", "Promoting a website", "Suspicious links", "Other"]);
reportMap.set("Impersonation",
["Impersonating a person", "Impersonating a business", "Impersonating a public figure", "Other"]);
reportMap.set("Intellectual Property infringement",
["Plagiarism", "Trademark infringement", "Copyright infringement", "Other"]);
reportMap.set("Misleading content",
["Misleading information", "Misleading advertising", "Misleading promotions", "Other"]);

const reportMapKeys = Array.from(reportMap.keys());
const maxReasonCharacterCount = 250;

function ReportChoiceModal({postID, user, isComment = false, commentID = null}) {

  const [showChoice, setShowChoice] = useState(false);

  const [choice, setCheckChoice] = useState("");
  const [reason, setReason] = useState("");

  const handleCloseChoice = () => setShowChoice(false);
  const handleShowChoice = () => {
    async function checkIfPostWasAlreadyReported() {
        let alreadyReported;
        if(isComment) {
            alreadyReported = await hasUserReportedComment(user.uid, postID, commentID);
        } else {
            alreadyReported = await hasUserReportedPost(user.uid, postID);
        }
        if (alreadyReported) {
            swal ({
                title: "You have already reported this " + (isComment ? "comment" : "post"),
                icon: "info",
                buttons: {
                    confirm: {
                        text: "Ok",
                        value: true,
                        className: "btn-success",
                    },
                },
                buttonsStyling: "align-self: center;",
                dangerMode: true,
            });
            cancelReport();
        } else {
            setShowChoice(true);
        }
    }


    if (user === null) {
        swal ({
            title: "You must be logged in to report a" + (isComment ? "comment" : "post"),
            icon: "info",
            buttons: {
                confirm: {
                    text: "Ok",
                    value: true,
                    className: "btn-success",
                },
            },
            buttonsStyling: "align-self: center;",
            dangerMode: true,
        });
        cancelReport();
    }
    else if (isComment && commentID === null) {
        swal ({
            title: "Error reporting comment",
            icon: "error",
            buttons: {
                confirm: {
                    text: "Ok",
                    value: true,
                    className: "btn-success",
                },
            },
            buttonsStyling: "align-self: center;",
            dangerMode: true,
        });
        cancelReport();
    } 
    checkIfPostWasAlreadyReported(); 
  }

  const [isReportReason, setIsReportReason] = useState(false);
  const [reportChoiceIndex, setReportChoiceIndex] = useState(0);

  // function for submitting the report after their option has been selected
  function moveToReportReason(subChoice) {
    setCheckChoice(prevChoice => {
        prevChoice += ": " + subChoice;
        return prevChoice;
    });
    setIsReportReason(true);
  }

  function cancelReport() {
    setCheckChoice("");
    setReason("");
    setReportChoiceIndex(0);
    handleCloseChoice();
    setIsReportReason(false);
  }

  const submitReport = async () => {
    if (reason === "" || reason === null) {
        return toast.error("Please enter a reason for reporting");
    }
    // guests cannot report, so this wont ever be null
    let userUID = user.uid;

    // submit functionality 

    try {
        let reported;
        if(isComment){
            reported = await updateCommentReports(userUID, postID, commentID, choice, reason);
        } else {
            reported = await updatePostReports(userUID, postID, choice, reason);
        }
        setReason("");
        if (reported) {
            handleCloseChoice();
            swal ({
                title: "This " + (isComment ? "comment" : "post") + " has been reported, thank you for your help!",
                icon: "success",
                buttons: {
                    confirm: {
                        text: "Ok",
                        value: true,
                        className: "btn-success",
                    },
                },
                buttonsStyling: "align-self: center;",
                dangerMode: true,
            });

            // get the post info, tell poster that their post was reported, and tell admins that a post was reported
            if(!isComment){
                const post = await getPost(postID);
                notifyPosterPostTakenDown(post);
                broadcastToAdminsReport(post);
            }
        }
    } catch (error) {
        console.log(error);
        toast.error("Error reporting " + (isComment ? "comment" : "post"));
    }
  }

  function notifyPosterPostTakenDown(post) {
    const message = "Someone reported your " + (isComment ? "comment" : "post") + ", and your " + (isComment ? "comment" : "post") + " is under review. In this time frame your " + (isComment ? "comment" : "post") + " will not be visible in our website. No action needed from your end."
    const notification = {
        message: message,
        type: "report",
        from: null,
        url: "/userPosts",
    }
    addNotification(post.author_uid, notification);
  }

  async function broadcastToAdminsReport(post) {
    const message = "A " + (isComment ? "comment" : "post") + " has been reported, please review it and take the appropriate action."
    const notification = {
        message: message,
        type: "report",
        from: null,
        url: `/admin`,
    }
    // obtain all admin and moderator uids and send them the notification
    const userDocs = await getUsers();
    const users = userDocs.map(user => user.data());
    for (let i = 0; i < users.length; i++) {
        if (users[i].role === "admin" || users[i].role === "moderator") {
            addNotification(users[i].uid, notification);
        }
    }
  }

  function handleReportSelection(index, key) {
    setReportChoiceIndex(index + 1);
    setCheckChoice(key);
  }

  function handleReportGoBack() {
    if (isReportReason) {
        setCheckChoice(prevChoice => {
            let splitChoice = prevChoice.split(": ");
            return splitChoice[0];
        })
        setIsReportReason(false);
    } else {
        setCheckChoice("");
        setReportChoiceIndex(0);
    }
  }

  return (
    <>
        <a href="javascript:;" className="m-r-15 text-inverse-lighter" onClick={handleShowChoice}>
            <i className="fa fa-regular fa-flag fa-fw fa-lg m-r-3"></i>
            <a href="javascript:;" className='shareText' >Report</a>
        </a>
 
        <Modal
            centered
            show={showChoice}
            onHide={handleCloseChoice}
            backdrop="static"
            keyboard={false}
        >
        <Modal.Header>

        <FontAwesomeIcon className={reportChoiceIndex === 0 ? "hidden-element" : "font-awesome-button"} onClick={handleReportGoBack} icon={faArrowLeft} />
      
        <Modal.Title className="text-center">Report {isComment ? "Comment" : "Post"}</Modal.Title>
        <FontAwesomeIcon onClick={cancelReport} className="font-awesome-button" icon={faTimes} />
        </Modal.Header>
        
        {!isReportReason ?
            // display the report choice options
            <Modal.Body>
                <ListGroup>
                <ListGroupItem className="list-group-item-header"><h1>{reportChoiceIndex === 0 ? "Please choose an option" : `What kind of ${choice}?`}</h1>
                <p>If there looks to be immediate danger, please get help now before waiting for Ux Was Here.</p></ListGroupItem>
                    {// return the listing of all the high level report types
                        reportChoiceIndex === 0 ? reportMapKeys.map((key, index) => {
                        return <ListGroup.Item onClick={() => handleReportSelection(index, key)}>
                        {key}
                        </ListGroup.Item>
                    }) // return the listing of the subtypes of the report type selected
                        : reportMap.get(reportMapKeys[reportChoiceIndex - 1]).map((key) => {
                            return <ListGroup.Item onClick={() => moveToReportReason(key)}>
                                {key}
                            </ListGroup.Item>
                        })
                    }
                </ListGroup> 
            </Modal.Body>
            : // display the report reason input
            <>
            <Modal.Body>
                <h1>Please provide your reasoning for the report</h1>
                <textarea maxLength={maxReasonCharacterCount} onChange = {(e) => {
                    setReason(e.target.value);
                }}></textarea>
                <div>{reason.length} of {maxReasonCharacterCount} characters entered</div>
            </Modal.Body>
            <Modal.Footer>
                <button className="adminBtn btn" variant="primary"onClick={submitReport}>Report {isComment ? "Comment" : "Post"}</button>
                </Modal.Footer>
            </>
        }
        </Modal>
    </>
  );
}

export default ReportChoiceModal;