import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/login.css';
import { FcGoogle } from "react-icons/fc";
import { BsFacebook } from "react-icons/bs";
import { GitHub } from '@material-ui/icons';
import { auth, fbFirestore } from "../firebase/firebaseConfig";
import { UserAuth, googleSignIn, facebookSignIn } from '../context/AuthContext';
import googleLogo from '../imgs/image-30@2x.png';
import facebookLogo from '../imgs/image-33@2x.png';
import appleLogo from '../imgs/image-55@2x.png';
import linkedInLogo from '../imgs/image-56@2x.png';
import svg from '../imgs/frame-61.svg';
import {
  updateDoc, 
  doc,
} from "firebase/firestore";


// Function that returns the login page
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn, user } = UserAuth();

  // If the user is logged in, redirect to the home page using a useEffect
  React.useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);
  


  // Function that handles the submission of the user info
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signIn(email, password);
      navigate('/')

      window.location.reload();
      await updateDoc(doc(fbFirestore, "users", auth.currentUser.uid), {
        isOnline: true,
      }); 
    } catch (e) {
      setError(e.message);
      alert(e.message);
      // let errorAlert = document.getElementById('errorText');
      // errorAlert.innerHTML = e.message;
      // errorAlert.style.backgroundColor = "rgb(250, 166, 166)";
      // errorAlert.style.padding = "5px";
      // errorAlert.style.margin = "0 10px 5px 10px";
      // errorAlert.style.borderRadius = "3px";
      // errorAlert.style.fontSize = "1rem"; 
      // errorAlert.style.textAlign = "center"; 

      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);
      document.getElementById('loginForm').reset();

    }

  };

 // Function that handles Google Sign In
  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await googleSignIn();
      navigate("/");
      
    } catch (e) {
      setError(e.message);
      console.log(error.message);
    }
  };

// Function that handles Facebook Sign In
  const handleFacebookSignIn = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await facebookSignIn();
      navigate("/");
      
    } catch (e) {
      setError(e.message);
      console.log(error.message);
    }
  };
  
  // The HTML that is being returned as Login component
  return (
    





 <form onSubmit= {handleSubmit} className='formContainer' id='loginForm'> 
    {/* <form onSubmit= {handleSubmit}  id='loginForm'> 
    <div className="relative bg-gray-200 w-full h-[1080px] overflow-hidden text-left text-xs text-darkgray font-m3-label-large">
      <div className="absolute top-[0px] left-[0px] bg-black w-[142px] h-[43px]" />
      <div className="absolute top-[calc(50%_-_333px)] left-[calc(50%_-_275px)] rounded-mini bg-gray-600 shadow-[0px_4px_26px_rgba(0,_0,_0,_0.25)] [backdrop-filter:blur(4px)] w-[551px] h-[667px] overflow-hidden">
        <div className="absolute top-[151px] left-[168px] bg-m3-sys-light-on-primary w-3.5 h-3.5 overflow-hidden" />
        <div className="absolute top-[125px] left-[152px] w-[247px] h-[417px]">
          <button type="submit" className="absolute top-[190px] left-[2px] rounded-81xl bg-cornflowerblue-100 w-[245px] h-10 overflow-hidden flex flex-col items-center justify-center text-center text-sm text-m3-sys-light-on-primary">
            <div className="self-stretch flex-1 flex flex-row py-2.5 px-6 items-center justify-center">
              <div className="relative tracking-[0.1px] leading-[20px] font-medium">
                Continue
              </div>
            </div>
          </button>
          <input type="email" onChange={ (e) => setEmail(e.target.value)} className="absolute top-[56px] left-[calc(50%_-_121.5px)] rounded-lg bg-gray-500 box-border w-[244px] h-9 overflow-hidden border-[0.5px] border-solid border-gray-400" />
          <input type="password" className="absolute top-[110px] left-[calc(50%_-_121.5px)] rounded-lg bg-gray-500 box-border w-[244px] h-9 overflow-hidden border-[0.5px] border-solid border-gray-400" onChange={ (e) => setPassword(e.target.value)}/>
            <img  
            className="absolute top-[110px] left-[calc(50%_-_121.5px)] rounded-lg w-[244px] h-9 overflow-hidden"
            alt=""
            src={svg}
            />
          <div className="absolute top-[102px] left-[26px] bg-gray-100 flex flex-row items-center justify-center">
            <div className="relative leading-[16px] font-medium" >Password</div>
          </div>
          <b className="absolute top-[0px] left-[calc(50%_-_79.5px)] text-5xl leading-[16px] text-darkslategray">
            Welcome Back
          </b>
          <div className="absolute top-[48px] left-[26px] bg-gray-100 w-[30px] h-4">
            <div className="absolute top-[0px] left-[0px] leading-[16px] font-medium" >
              Email
            </div>
          </div>
          <b className="absolute top-[357px] left-[0px] leading-[16px]">
            Don’t have an account?
          </b>
          <Link className="Link" to="/reset"><b className="absolute top-[160px] left-[67px] leading-[16px]">
            Forgot your password?
          </b></Link>
          
          <div className="absolute top-[381px] left-[0px] leading-[16px] flex items-center w-[239px] h-9">
            <span className="[line-break:anywhere] w-full">
              <span>{`By creating your account, you agree to the `}</span>
              <span className="[text-decoration:underline] font-semibold">
                Terms of Service
              </span>
              <span>{` and `}</span>
              <span className="[text-decoration:underline] font-semibold">
                Privacy Policy
              </span>
            </span>
          </div>
          <Link className="Link" to="/signup"><div className="absolute top-[357px] left-[134px] leading-[16px] text-cornflowerblue-200">
            Create one here
          </div></Link>
          
        </div>
        <div className="absolute top-[369px] left-[calc(50%_-_39.5px)] text-sm leading-[16px] font-medium">
          Sign in with
        </div>
        <div className="absolute top-[378.5px] left-[151.5px] box-border w-[77px] h-px border-t-[1px] border-solid border-gray-300" />
        <div className="absolute top-[378.5px] left-[320.5px] box-border w-[78px] h-px border-t-[1px] border-solid border-gray-300" />
        <div className="absolute top-[417px] left-[152px] w-[246px] flex flex-row items-start justify-start gap-[32px]">
          <div className="rounded-8xs overflow-hidden flex flex-row py-[5px] px-2.5 items-start justify-start border-[0.5px] border-solid border-silver">
            <img
              className="relative w-[18px] h-[18px] object-cover"
              alt=""
              src={facebookLogo}
              onClick={handleFacebookSignIn}
            />
          </div>
          <div className="rounded-8xs overflow-hidden flex flex-row py-[5px] px-2.5 items-start justify-start border-[0.5px] border-solid border-silver">
            <img
              className="relative w-[18px] h-[18.4px] object-cover"
              alt=""
              src={googleLogo}
              onClick={handleGoogleSignIn}
            />
          </div>
          <div className="rounded-8xs overflow-hidden flex flex-row py-[5px] px-2.5 items-start justify-start border-[0.5px] border-solid border-silver">
            <img
              className="relative w-[18px] h-[19px] object-cover"
              alt=""
              src={linkedInLogo}
            />
          </div>
          <div className="rounded-8xs overflow-hidden flex flex-col py-[5px] px-[7px] items-start justify-start border-[0.5px] border-solid border-silver">
            <img
              className="relative w-[23px] h-[18px] object-cover"
              alt=""
              src={appleLogo}
            />
          </div>
        </div>
      </div>
    </div> */}

    <div className='container'>
      <div className='loginContainer'>
         <h1 className='loginText'>Login</h1>
         <h3 id="errorText"></h3>
        <div className="emailInputContainer">
          <input type="email" id="emailAddress" className="form-control" name="email" onChange={ (e) => setEmail(e.target.value)}/>
          <label htmlFor="emailAddress">Email address</label>
        </div>
        <div className="passwordInputContainer">
            <input type="password" id="password" className="form-control"  name="password" onChange={ (e) => setPassword(e.target.value)}/>
            <div className='passwordTextContainer'>
                <label htmlFor="form2Example2">Password</label>
                <label className='forgotPassword'><Link className="Link" to="/reset"><h6 className='registertext'>Forgot Password?</h6></Link></label>
            </div>
        </div>

        <button type="submit" className="signInBtn">Sign in</button>

        <h4 className='line'><span> or </span></h4>
        <div className="otherSignInContainer" >

            <button type="button" className="otherSignInButtons" onClick={handleGoogleSignIn}>
              <FcGoogle className='signIcon'/> <span className='otherSignText'>Sign in with Google</span>
            </button>

            <button type="button" className="otherSignInButtons" onClick={handleFacebookSignIn}>
              <BsFacebook className='facebookSign'/>     <span className='otherSignText'>Sign in with Facebook</span>
            </button>
      
        </div>

        <div className="otherSignOptionContainer">
            <div className='noAccountContainer'>
                <h6 className="noAccountText">Don't have an account?</h6> 
                <Link className="Link" to="/signup"><h6 className='registertext'>Register</h6></Link>
            </div>

        </div>
              
        </div>
    </div>

  </form>
  )
}

export default Login;
