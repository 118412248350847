import React, {Component} from 'react';
import Lottie from 'react-lottie';
import animationPost from '../lotties/post.json';

import '../../styles/homepage.css';

class PostLotties extends Component {
    render() {
        const defaultOptions = {
          loop: true,
          autoplay: true,
          animationData: animationPost,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        };

        return (
            <div className='imageFeature'>
              <Lottie options={defaultOptions}/>
              </div>
          );
        }
}

export default PostLotties;