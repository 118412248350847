import React from 'react';
import "../styles/notFound.css";
import { Link } from 'react-router-dom';


const NotFoundPage = () => {

    return (
        <div className='notFoundContainer'>
            <div className='notFoundBanner'></div>
            <div className='notFoundContent'>
                <h2>The page you are trying to access doesn’t exist or has been moved.</h2>
                <h2>Try going back to our homepage.</h2>
                <Link className="home" to="/">
                    <button className='homeBtn'>Go to homepage</button>
                </Link>
            </div>
        </div>
    )
}

export default NotFoundPage;

