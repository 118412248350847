/**
 * Create the email form that submits emails to MailChimp. The html and functions for submitting are created by MailChimp.
 * When logged in to MailChimp, go to the Audience tab, click Signup forms, then click Embedded forms to generate html for a form.
 */

import React from 'react';
import "../styles/email.css";

const mcScript = "function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);"

class EmailForm extends React.Component {
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
        script.async = true;
        document.body.append(script);
        const script2 = document.createElement("script");
        script2.text = mcScript;
        script2.async = true;
        //document.body.append(script2);
    }

    render() {
        return (
            <div id="mc_embed_signup">
            <form action="https://uxwashere.us9.list-manage.com/subscribe/post?u=f09e957a2a760370eae3723a6&id=89f99d776c" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div id="mc_embed_signup_scroll">
                <h2>Subscribe</h2>
                <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span>
                </label>
                <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" />
                </div>
                <div className="mc-field-group input-group ux-exp-input-group">
                <strong>User Experience Level: </strong>
                <ul><li className='exp-radio-btn'><input type="radio" defaultValue={1} name="group[50627]" id="mce-group[50627]-50627-0" /><label htmlFor="mce-group[50627]-50627-0">Experienced</label></li>
                    <li className='exp-radio-btn'><input type="radio" defaultValue={2} name="group[50627]" id="mce-group[50627]-50627-1" /><label htmlFor="mce-group[50627]-50627-1">New</label></li>
                </ul>
                </div>
                <div id="mce-responses" className="clear foot">
                <div className="response" id="mce-error-response" style={{display: 'none'}} />
                <div className="response" id="mce-success-response" style={{display: 'none'}} />
                </div>    
                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_f09e957a2a760370eae3723a6_89f99d776c" tabIndex={-1} defaultValue /></div>
                <div className="optionalParent">
                <div className="clear foot">
                    <input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                    <p className="brandingLogo"><a href="http://eepurl.com/h1GkP5" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg" alt="" /></a></p>
                </div>
                </div>
            </div>
            </form>
            </div>
            )
    }
}

export default EmailForm;