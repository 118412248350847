import React from 'react'
import { MdCancel } from "react-icons/md";

export default function banPage() {
  return (
    <div className='emptyPost'>
      <div className='postIcon'>
        <h1><MdCancel /></h1>
      </div>
      <h2> Invalid Access </h2>
      <h6> This account has been banned due to violating the community guidelines. </h6>
    </div>
  )
}
