/**
 * CRUD functions for Firebase
 */

// import { fbFirestore, fbRealtime, auth } from './firebaseConfig';
import { fbFirestore, auth } from './firebaseConfig';

import {
  setDoc,
} from "firebase/firestore";

import { collection, getDoc, getDocs, query, limit, where, addDoc, serverTimestamp, doc, updateDoc, increment, arrayUnion, arrayRemove, orderBy, startAfter, deleteDoc, collectionGroup } from 'firebase/firestore';
// import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";

const postRef = collection(fbFirestore, "posts");
const userRef = collection(fbFirestore, "users");
const reportRef = collection(fbFirestore, "reports")
const communityRef = collection(fbFirestore, "communities");


/**
 * Get the notifications for a specific user.
 * @param {used to get the notifications for a specific user} userID 
 * @returns all notifications for a specific user
 */
const getNotifications = async (userID) => {
  const notificationRef = collection(fbFirestore, "notifications", userID, "userNotifications");
  const docs = await getDocs(notificationRef);
  return docs.docs;
};

const getUsers = async() => {
  const data = await getDocs(userRef);
  return data.docs;
}

const getCommunitiesJoined = async (userID) => {
  try {
    const userDocRef = doc(userRef, userID);
    const userDoc = await getDoc(userDocRef);
    const communitiesJoined = userDoc.data().communitiesJoined ?? [];
    console.log ("userID: " + userID);
    console.log ("communitiesJoined: " + communitiesJoined);
    const communityData =  getCommunityData(communitiesJoined);
    return communityData;
  }
  catch (error) {
    console.log("Error fetching communities joined:", error);
  }
  
}


const getCommunitiesModerated = async (userID) => {

  try {
    const userDocRef = doc(userRef, userID);
    const userDoc = await getDoc(userDocRef);
    const communitiesModerated = userDoc.data().localModeratorCommunities ?? [];
    console.log ("userID: " + userID);
    console.log ("communitiesModerated: " + communitiesModerated);
    const communityData =  getCommunityData(communitiesModerated);
    return communityData;
  }
  catch (error) {
    console.log("Error fetching communities moderated:", error);
  }
}

const getCommunityData = async (communitiesArray) => {
 
    try {
      const communityData = await Promise.all(
        communitiesArray.map(async (communityID) => {
          const communityDocRef = doc(communityRef, communityID);
          const communitySnapshot = await getDoc(communityDocRef);
          if (communitySnapshot.exists()) {
            return communitySnapshot.data();
          } else {
            const error = "Community does not exist";
            return error;
          }
        })

      );
      console.log ("community data: " + communityData);
      return communityData;
    } catch (error) {
      console.log("Error fetching community data:", error);
    }
}

const getUsersByRole = async (role) => {
  const data = await getDocs(query(userRef, where("role", "==", role)));
  return data.docs;
}

/**
 * Add a notification to the user's notification list. The format of the notification is a json object which must be structured as follows:
 * {
 *  "type": "string",
 *  "message": "string",
 *  "from: "string (id of the user who sent the notification)"
 *  "url": "string (url to redirect to when the notification is clicked)"
 * }
 * @param {id of the user to add the notification under} userID 
 * @param {json data for the notification} notification 
 */
const addNotification = async (userID, notification) => {
  const notificationRef = collection(fbFirestore, "notifications", userID, "userNotifications");
  const notificationsDoc = doc(notificationRef);
  await setDoc(notificationsDoc, {
    notification,
    timestamp: serverTimestamp(),
    isNew: true,
  });
};

/**
 * Remove all the notifications for a specific user.
 * @param {id of the user to remove the notifications under} userID 
 */
const clearNotifications = async (userID) => {
  const notificationRef = collection(fbFirestore, "notifications", userID, "userNotifications");
  const docs = await getDocs(notificationRef);
  docs.docs.forEach(async (doc) => {
    await deleteDoc(doc.ref);
  });
};

/**
 * Get all posts. 
 * @returns an array of posts. Use .data() on an element of the array to retrieve post data, 
 * and .id to retrieve the post's ID
 */
const getPosts = async () => {
  const data = await getDocs(postRef);
  data.docs.forEach(element => {
    // console.log(element.data());
    // console.log(element.id);
  });
  return data.docs;
}

const updateBio = async (userID, userBio) => {
  const user1DocRef = doc(userRef, userID)
  await updateDoc(user1DocRef, {
    bio: userBio,
  })
}

const updateRole = async (userID, role) => {
  const user1DocRef = doc(userRef, userID)
  await updateDoc(user1DocRef, {
    role: role,
  })
}

const updateLinkedin = async (userID, linkedin) => {
  const user1DocRef = doc(userRef, userID)
  await updateDoc(user1DocRef, {
    linkedinURL: linkedin,
  })
}

const updateInstagram = async (userID, instagram) => {
  const user1DocRef = doc(userRef, userID)
  await updateDoc(user1DocRef, {
    instagramURL: instagram,
  })
}

const updateTwitter = async (userID, twitter) => {
  const user1DocRef = doc(userRef, userID)
  await updateDoc(user1DocRef, {
    twitterURL: twitter,
  })
}


/**
 * Get the first posts ordered from newest to oldest
 * @param {*} postLimit Integer. The number of posts to retrieve
 * @returns an array of posts. Use .data() on an element of the array to retrieve post data, 
 * and .id to retrieve the post's ID
 */
const getFirstPostsByDate = async (postLimit) => {
  console.log("getting first posts");
  const querySnapshot = await getDocs(
    query(
      postRef,
      where("flagged", "==", false),  // Updated to use `false` instead of "false"
      orderBy("time", "desc"),
      limit(postLimit)
    )
  );
  return querySnapshot.docs;
}

const getFirstUserPostsByDate = async (postLimit, userID) => {
  const querySnapshot = await getDocs(
    query(
      postRef,
      where("author_uid", "==", userID),
      where("flagged", "==", false), 
      orderBy("time", "desc"),
      limit(postLimit)
    )
  );
  return querySnapshot.docs;
}

const getNextUserPostsByDate = async (lastDoc, postLimit, userID) => {
  const querySnapshot = await getDocs(
    query(
      postRef, 
      where("author_uid", "==", userID), 
      where("flagged", "==", false), 
      orderBy("time", "desc"), 
      startAfter(lastDoc), 
      limit(postLimit)
    )
  );
}

/**
 * Get the next posts after the given last document, ordred newest to oldest.
 * @param {*} lastDoc Integer. The number of posts to retrieve
 * @param {*} postLimit an array of posts. Use .data() on an element of the array to retrieve post data, 
 * and .id to retrieve the post's ID
 * @returns 
 */
const getNextPostsByDate = async (lastDoc, postLimit) => {
  const next = query(postRef, orderBy("time", "desc"), startAfter(lastDoc), limit(postLimit));
  const docSnap = await getDocs(next);
  return docSnap.docs;
}

/**
 * Returns the user document with the given ID
 * @param {*} userID String. The ID of the user whose document is being retrieved.
 * @returns The document. Use .data() to retrieve the fields and .id to get the id.
 */
const getUserInfo = async (userID) => {
  const docRef = doc(userRef, userID);
  const data = await getDoc(docRef);
  return data;
}

const getPostInfo = async (postId) => {
  const first = query(postRef, where("post_ID", "==", postId));
  const docSnap = await getDocs(first);
  return docSnap.docs;
}

const upsertPost = async (postID, name, uid, title, content, imageURL, storage_path, userPhotoURL, community_ID = null) => {
  console.log("upserting post");

  if (postID) { // if postID is not null, update the post
    // get post DocRef
    const docRef = doc(postRef, postID);
    // update post with new data
    await updateDoc(docRef, {
      title: title,
      content: content,
      image: imageURL,
      storage_path: storage_path
    })
  } else { // if postID is null, create a new post
    // make post DocRef
    const makeDocRef = await addDoc(postRef, {
      author_name: name,
      author_uid: uid,
      author_photoURL: userPhotoURL,
      comments: 0,
      title: title,
      content: content,
      image: imageURL,
      storage_path: storage_path,
      likes: 0,
      time: serverTimestamp(),
      reports: 0,
      flagged: false,
      community_ID: community_ID,
    });
    // update post with post_ID

    const post_ID = makeDocRef.id;
    await updateDoc(makeDocRef, {
      post_ID: post_ID,
    })
  }

}

const upsertCommunity = async (communityData) => {
  console.log("upserting community");

  const {community_ID, userID, communityName, bio, imageURL, storage_path, isRequest, joinRequestUsers} = communityData;

  try {
    // if community id is not null, then update the community
    if (community_ID) { 

      const updateData = {};

      if (communityName !== undefined) {
        console.log("community name", communityName);
        updateData.name = communityName;
      }
    
      if (bio !== undefined) {
        console.log("community bio", bio);
        updateData.bio = bio;
      }
    
      if (imageURL !== undefined) {
        console.log("community image url", imageURL);
        updateData.imageURL = imageURL;
      }
    
      if (storage_path !== undefined) {
        console.log("community storage path", storage_path);
        updateData.storage_path = storage_path;
      }
    
      if (isRequest !== undefined) {
        console.log("community is request", isRequest);
        updateData.isRequest = isRequest;
      }

      if (joinRequestUsers !== undefined) {
        console.log("community join request", joinRequestUsers);
        updateData.joinRequestUsers = joinRequestUsers;
      }

      // get post DocRef
      const docRef = doc(communityRef, community_ID);
      // update post with new data, joinRequest should be array unioned
      await updateDoc(docRef, updateData);

      return;
    }

    // if community id is null, then create a new community
    const makeDocRef = await addDoc(communityRef, {
      name: communityName,
      bio: bio,
      image: imageURL,
      storage_path: storage_path,
      creatorID: userID,
      timestamp: serverTimestamp(),
      postIDs: [],
      userIDs: [userID],
      joinRequestUsers: [],
      isRequest: true,
      community_ID: "",
    });

    // update community with community_ID
    const communityID = makeDocRef.id;
    await updateDoc(makeDocRef, {
      community_ID: communityID,
    });

  } catch (err) {
    console.log(err);
  }
}

/**
 * Adds a user to the community
 * @param {} community_ID is the id of the community to add the user to
 * @param {*} userID is the id of the user
 */
async function addUserToCommunity(community_ID, userID) {
    // get the community and user data
    const communityDocRef = doc(communityRef, community_ID);
    const userDocRef = doc(userRef, userID)
    const [communityDoc, userDoc] = await Promise.all([
      getDoc(communityDocRef), 
      getDoc(userDocRef)
    ]);
    const communityData = communityDoc.data();
    const userData = userDoc.data();

    // add the community to the user
    if (communityData.creatorID === userID) {
        // if they are the creator of the community and are a user, add them as a local moderator for it
        const isUser = userData.role === "user";
        await updateDoc(userDocRef, {
          role: isUser ? "local-moderator" : userData.role,
          localModeratorCommunities: arrayUnion(community_ID),
          communitiesJoined: arrayUnion(community_ID),
        });
    } else {
      console.log(userID);
      // otherwise, add them as a member of the community and remove the join request
      await updateDoc(userDocRef, {
        communitiesJoined: arrayUnion(community_ID)
      });

      // remove the join request
      await updateDoc(communityDocRef, {
        joinRequestUsers: arrayRemove(userID)
      });
    }

    // add the user to the community
    await updateDoc(communityDocRef, {
        userIDs: arrayUnion(userID)
    });
}

async function removeUserFromCommunity(userID, community_ID) {
     // get the community and user data
     const communityDocRef = doc(communityRef, community_ID);
     const userDocRef = doc(userRef, userID);
     const [communityDoc, userDoc] = await Promise.all([
       getDoc(communityDocRef),
       getDoc(userDocRef)
     ]);
   
     const communityData = communityDoc.data();
     const userData = userDoc.data();
   
     const updatedCommunityData = {
       userIDs: communityData.userIDs.filter(id => id !== userID)
     };
   
     const updatedUserData = {
       communitiesJoined: userData.communitiesJoined.filter(id => id !== community_ID),
       localModeratorCommunities: userData.localModeratorCommunities.filter(id => id !== community_ID)
     };
   
     await Promise.all([
       updateDoc(communityDocRef, updatedCommunityData),
       updateDoc(userDocRef, updatedUserData)
     ]);
}

const getCommunities = async () => {
  const data = await getDocs(communityRef);
  return data.docs;
}

const getCommunity = async (community_ID) => {
  const docRef = doc(communityRef, community_ID);
  const data = await getDoc(docRef);
  return data;
}

const updateCommunity = async (community_ID, name = null, bio = null, imageURL = null, storage_path) => {
  // update all the items in community that are not null passed in
  const docRef = doc(communityRef, community_ID);
  const dataToUpdate = {};

  if (name) {
    dataToUpdate.name = name;
  }

  if (bio) {
    dataToUpdate.bio = bio;
  }

  if (imageURL) {
    dataToUpdate.imageURL = imageURL;
  }

  if (storage_path) {
    dataToUpdate.storage_path = storage_path;
  }

  await updateDoc(docRef, dataToUpdate);
};

/**
 * Upvotes or Downvotes a post
 * @param {*} userID The user liked the post
 * @param {*} postID The post being liked
 * @returns user ref and post ref
 */
const likePost = async (userID, postID) => {
  console.log("liking post");
  // get user and post docs
  const userDocRef = doc(userRef, userID);
  const postDocRef = doc(postRef, postID);
  const userDoc = await getDoc(userDocRef);
  const postDoc = await getDoc(postDocRef);
  const userLikedPosts = userDoc.data().likedPosts ?? [];
  const postLikes = postDoc.data().likes;

  if (userLikedPosts?.includes(postID)) { //unliking the post
    await updateDoc(userDocRef, { likedPosts: arrayRemove(postID) })
    await updateDoc(postDocRef, { likes: postLikes - 1 })
    return {
      userLikedPosts: userLikedPosts.filter(id => id !== postID),
      postLikes: postLikes - 1
    }
  } else { //liking the post
    await updateDoc(userDocRef, { likedPosts: arrayUnion(postID) })
    await updateDoc(postDocRef, { likes: postLikes + 1 })
    return {
      userLikedPosts: [...userLikedPosts, postID],
      postLikes: postLikes + 1
    }
  }
}

/**
 * Add or remove the given post ID to the array of posts that have been liked by the user
 * @param {*} userID String. The user's ID
 * @param {*} postID String. The post's ID
 * @param {*} adding Boolean. True if the post is being added to the list of liked posts, 
 * false if it is being removed.
 * @returns the docRef
 */
const updateUserLikedPosts = async (userID, postID, adding) => {
  const docRef = doc(userRef, userID);
  if (adding) {
    await updateDoc(docRef, {
      likedPosts: arrayUnion(postID)
    });
  } else {
    await updateDoc(docRef, {
      likedPosts: arrayRemove(postID)
    });
  }
  return docRef;
}

/**
 * Add or remove the given comment ID to the array of comments that have been liked by the user
 * @param {*} userID String. The user's ID
 * @param {*} commentID String. The comment's ID
 * @param {*} adding Boolean. True if the comment is being added to the list of liked comments, 
 * false if it is being removed.
 * @returns the docRef
 */
const updateUserLikedComments = async (userID, commentID, adding) => {
  const docRef = doc(userRef, userID);
  if (adding) {
    await updateDoc(docRef, {
      likedComments: arrayUnion(commentID)
    });
  } else {
    await updateDoc(docRef, {
      likedComments: arrayRemove(commentID)
    });
  }
  return docRef;
}

const likeComment = async (postID, userID, commentID) => {

  console.log("liking comment");  

  const userDocRef = doc(userRef, userID);
  const docRef = doc(postRef, postID);
  const repliesRef = collection(docRef, "replies");
  const replyRef = doc(repliesRef, commentID);
  const replyDoc = await getDoc(replyRef);
  const userDoc = await getDoc(userDocRef);
  const userLikedComments = userDoc.data().likedComments  ?? [];

 try {
  if (userLikedComments.includes(commentID)) { //unliking the comment
    await updateDoc(userDocRef, { likedComments: arrayRemove(commentID) })
    await updateDoc(replyRef, { likes: replyDoc.data().likes - 1 })
    return {
      userLikedComments: userLikedComments.filter(id => id !== commentID),
      commentLikes: replyDoc.data().likes - 1
    }
  } else { //liking the comment
    await updateDoc(userDocRef, { likedComments: arrayUnion(commentID) })
    await updateDoc(replyRef, { likes: replyDoc.data().likes + 1 })
    return {
      userLikedComments: [...userLikedComments, commentID],
      commentLikes: replyDoc.data().likes + 1
    }
  }
 } catch (err) {
   console.log(err);
 }
}

/**
 * Increment or decrement likes by one for the post with the given ID.
 * @param {*} postID String. The ID of the post being updated.
 * @param {*} like Boolean. True if the post is being liked, false if it is being disliked.
 */
const updatePostLikes = async (postID, like) => {
  const ratingChange = like ? 1 : -1;
  const docRef = doc(postRef, postID);
  await updateDoc(docRef, {
    likes: increment(ratingChange)
  })
}

/**
 * Add a new report to the list of reports in the post document if they haven't already
 * @param {*} userID String. The ID of the user who reported the post.
 * @param {*} postID String. The ID of the post being reported.
 * @param {*} choice String. The choice the user made when reporting the post.
 * @param {*} reason String. The reason the user gave for reporting the post.
 * @returns true if the post was reported, false if the user has already reported the post
 */
const updatePostReports = async(userID, postID, choice, reason) => {

  // shove all the details of the report into a json format
  const reportDetails = {
    userID: userID,
    choice: choice,
    reason: reason,
  }

  const postDoc = await getPostInfo(postID);
  const postData = postDoc[0].data();

  // check if the user has already reported the post
  const isAlreadyReported = await hasUserReportedPost(userID, postID, postDoc);
  if (isAlreadyReported) {
    return false;
  }

  // update the report section of the post document
  // flag the reported post so that it is not shown to the users outside of the admin panel. A post is flagged if it has 1 report for now

  const shouldBeFlagged = true;
  const postDocRef = doc(postRef, postID);
  await updateDoc(postDocRef, {
    reports: {
      reportDetails: !postData.reports ? arrayUnion(reportDetails) : arrayUnion(...postData.reports.reportDetails, reportDetails),
      count: postData.reports?.reportDetails ? postData.reports.reportDetails.length + 1 : increment(1)
    },
    flagged: shouldBeFlagged
  });
  return true;
};

const updateCommentReports = async(userID, postID, commentID, choice, reason) => {

  // shove all the details of the report into a json format
  const reportDetails = {
    userID: userID,
    choice: choice,
    reason: reason,
  }

  const docRef = doc(postRef, postID);
  const replyCollection = collection(docRef, "replies");
  const commentRef = doc(replyCollection, commentID);
  const commentDoc = await getDoc(commentRef);
  const commentData = commentDoc.data();

  // check if the user has already reported the post
  const isAlreadyReported = await hasUserReportedComment(userID, postID, commentID, commentDoc);
  if (isAlreadyReported) {
    return false;
  }

  // update the report section of the post document
  // flag the reported post so that it is not shown to the users outside of the admin panel. A post is flagged if it has 1 report for now

  const shouldBeFlagged = true;
  await updateDoc(commentRef, {
    reports: {
      reportDetails: !commentData.reports ? arrayUnion(reportDetails) : arrayUnion(...commentData.reports.reportDetails, reportDetails),
      count: commentData.reports?.reportDetails ? commentData.reports.reportDetails.length + 1 : increment(1)
    },
    flagged: shouldBeFlagged
  });
  return true;
};

/**
 * Checks to see if a post was reported by the user already
 * 
 * @param {Id of the user to check if it is on the post report already} userID 
 * @param {Id of the post} postID 
 * @param {The post document} postDoc pass in the post document if you already have it, otherwise it will be retrieved
 * @returns true if the user id is already on the post report, false otherwise
 */
const hasUserReportedPost = async (userID, postID, postDoc = null) => {
    if (postDoc == null) {
      postDoc = await getPostInfo(postID);
    }
    const postData = postDoc[0].data();
    if (postData.reports) {
      const reportedUserIds = postData.reports.reportDetails.map(report => report.userID);
      if (reportedUserIds.includes(userID)) {
        return true;
      }
    }
    return false;
};

const hasUserReportedComment = async (userID, postID, commentID, commentDoc = null) => {
  if(commentDoc == null){
    const docRef = doc(postRef, postID);
    const replyCollection = collection(docRef, "replies");
    const commentRef = doc(replyCollection, commentID);
    commentDoc = await getDoc(commentRef);
  }
  
  const commentData = commentDoc.data();
  if (commentData.reports) {
    const reportedUserIds = commentData.reports.reportDetails.map(report => report.userID);
    if (reportedUserIds.includes(userID)) {
      return true;
    }
  }
  return false;
};

/**
 * Sets the post to being flagged so that it is not shown to the users
 * @param {*} postID String. The ID of the post being updated.
 * @returns true if the post was reported, false if the user has already reported the post
 **/
const removePostReports = async (postID) => {
  const postDocRef = doc(postRef, postID);
  try {
    await updateDoc(postDocRef, {
      reports: {
        reportDetails: [],
        count: 0
      },
      flagged: false
    });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

/**
 * Add the given post ID to the array of posts that have been reported by the user, and don't do anything if user has already reported the post
 * @param {*} userID String. The user's ID
 * @param {*} postID String. The post's ID
 * @param {*} adding Boolean. True if the post is being added to the list of liked posts, 
 * false if it has been already reported.
 * @returns the docRef
 */
const updateUserReportedPosts = async (userID, postID, adding) => {
  const docRef = doc(userRef, userID);
  if (adding) {
    await updateDoc(docRef, {
      reportedPost: arrayUnion(postID)
    });
  }
  return docRef;
}

/**
 * Increment or decrement likes by one for the reply with the given ID.
 * @param {*} postID String. The ID of the parent post.
 * @param {*} replyID String. The ID of the reply being updated.
 * @param {*} like Boolean. True if the reply is being liked, false if it is being disliked.
 */
const updateReplyLikes = async (postID, replyID, like) => {
  const ratingChange = like ? 1 : -1;
  const docRef = doc(postRef, postID);
  const replyCollection = collection(docRef, "replies");
  const replyRef = doc(replyCollection, replyID);
  await updateDoc(replyRef, {
    likes: increment(ratingChange)
  })
}

const uploadComment = async (postID, user, content) => {
  console.log("uploading comment")
  const docRef = doc(postRef, postID);
  const commentRef = collection(docRef, "replies");
  const commentDocRef = await addDoc(commentRef, {
    userID: user.data().uid,
    parentPost: postID,
    name: user.data().displayName,
    content: content,
    image: user.data().photoURL,
    likes: 0,
    time: serverTimestamp()
  });
  if (commentDocRef) {
    await updateDoc(commentDocRef, {
      id: commentDocRef.id
    });
    await updateDoc(docRef, {
      comments: increment(1)
    });
  }
  return commentDocRef;
}

/**
 * Get all replies to the post with the given ID.
 * @param {*} postID String. The ID of the post for which replies are being retrieved.
 * @returns An array of replies. Use .data() on an element of the array to retrieve reply data,
 * and .id to retrieve the reply's ID
 */
const getReplies = async (postID) => {
  const docRef = doc(postRef, postID);
  const replyRef = collection(docRef, "replies");
  const data = await getDocs(replyRef);
  // data.docs.forEach(doc => {
  //   console.log(doc.data());
  //   console.log(doc.id);
  // });
  return data.docs;
}

/**
 * Add a reply to the post with the given ID. Increment the comment count in the parent post.
 * @param {*} postID String. The ID of the post being replied to.
 * @param {*} name String. The name of the person making the reply.
 * @param {*} content String. The content of the reply.
 * @param {*} image String. The URL of an image stored in Firebase storage.
 * @param {*} userID String. The user's ID. Will be blank if the user is a guest
 */
const addReply = async (postID, name, content, image, userID) => {
  const docRef = doc(postRef, postID);
  const replyRef = collection(docRef, "replies");
  const replyDocRef = await addDoc(replyRef, {
    userID: userID,
    name: name,
    content: content,
    image: image,
    likes: 0,
    time: serverTimestamp()
  });
  console.log(replyDocRef)
  if (replyDocRef) {
    console.log("getting here")
    const comment_ID = replyDocRef.id;
    await updateDoc(replyDocRef, {
      comment_ID: comment_ID,
    })
    await updateDoc(docRef, {
      comments: increment(1)
    });
  }
  return replyDocRef;
}

const addReport = async (postID, userID) => {

  const makeDocRef = await addDoc(reportRef, {
    post_ID: postID,
    user_ID: userID
  });

  await updateDoc(makeDocRef, {
    report_ID: makeDocRef.id
  })
}

/**
 * Create a new post.
 * @param {*} email String. The user's email. 
 * @param {*} uid String. The user's UID.
 * @param {*} title String. The title of the post.
 * @param {*} content String. The content of the post.
 * @param {*} imageURL String. The URL of and image stored in Firebase storage.
 */
const makePost = async (name, uid, title, content, imageURL, storage_path, userPhotoURL) => {

  const makeDocRef = await addDoc(postRef, {
    author_name: name,
    author_uid: uid,
    author_photoURL: userPhotoURL,
    comments: 0,
    title: title,
    content: content,
    image: imageURL,
    storage_path: storage_path,
    likes: 0,
    time: serverTimestamp(),
    reports: 0,
    flagged: false
  });

  await updateDoc(makeDocRef, {
    post_ID: makeDocRef.id,
  })
}

/**
 * Updates the selected user post.
 * @param {*} postID String. The ID of the post being replied to.
 * @param {*} title String. The title of the post.
 * @param {*} content String. The content of the post.
 */
const updatePost = async (postID, title, content) => {
  const docRef = doc(postRef, postID);
  await updateDoc(docRef, {
    title: title,
    content: content,
  })
}

/**
 * 
 * @param {*} user1ID 
 * @param {*} user2ID 
 */
const followRequest = async (user1ID, user2ID) => {
  const user1DocRef = doc(userRef, user1ID)
  const user2DocRef = doc(userRef, user2ID)

  await getUserInfo(user2ID).then((user2) => {
    if (user2.data().requests.includes(user1ID)) {
      throw new Error("You have already sent a follow request to this user")
    } else {
      updateDoc(user2DocRef, {
        requests: arrayUnion(user1ID)
      }).then(() => {
        window.location.reload();
      })
    }
  })
}


/**
 * 
 * @param {*} user1ID 
 * @param {*} user2ID 
 */
const acceptRequest = async (user1ID, user2ID) => {
  const user1DocRef = doc(userRef, user1ID)
  const user2DocRef = doc(userRef, user2ID)

  updateDoc(user1DocRef, {
    followers: arrayUnion(user2ID),
    requests: arrayRemove(user2ID),
    friends: arrayUnion(user2ID)
  }).then(() => {
    updateDoc(user2DocRef, {
      following: arrayUnion(user1ID),
      friends: arrayUnion(user1ID)
    }).then(() => {
      window.location.reload();
    })
  })
}

/**
 * 
 * @param {*} user1ID 
 * @param {*} user2ID 
 */
const rejectRequest = async (user1ID, user2ID) => {
  const user1DocRef = doc(userRef, user1ID)
  const user2DocRef = doc(userRef, user2ID)

  updateDoc(user1DocRef, {
    requests: arrayRemove(user2ID)
  }).then(() => {
    window.location.reload();
  })
}

/**
 * 
 * @param {*} user1ID 
 * @param {*} user2ID 
 */
const followUser = async (user1ID, user2ID) => {
  console.log(user1ID + " | " + user2ID)
  const user1DocRef = doc(userRef, user1ID)
  const user2DocRef = doc(userRef, user2ID)

  updateDoc(user1DocRef, {
    followers: arrayUnion(user2ID)
  }).then(() => {
    updateDoc(user2DocRef, {
      following: arrayUnion(user1ID)
    })
    // .then(() => {
    //   window.location.reload();
    // })
  })
}

/**
 * 
 * @param {*} user1ID 
 * @param {*} user2ID 
 */
const unfollowUser = async (user1ID, user2ID) => {
  const user1DocRef = doc(userRef, user1ID)
  const user2DocRef = doc(userRef, user2ID)

  updateDoc(user1DocRef, {
    followers: arrayRemove(user2ID)
  }).then(() => {
    updateDoc(user2DocRef, {
      following: arrayRemove(user1ID)
    })
    // .then(() => {
    //   window.location.reload();
    // })
  })
}

/**
 * 
 * @param {*} user1ID
 * @param {*} user2ID 
 */
const removeFollower = async (user1ID, user2ID) => {
  const user1DocRef = doc(userRef, user1ID)
  const user2DocRef = doc(userRef, user2ID)

  updateDoc(user1DocRef, {
    followers: arrayRemove(user2ID)
  }).then(() => {
    updateDoc(user2DocRef, {
      following: arrayRemove(user1ID)
    }).then(() => {
      window.location.reload();
    })
  })
}

/**
 * Check user authentication.
 * @returns Object. The user object.
 * @returns null. If no user is logged in.
 */
const checkAuth = async () => {
  let userObject;
  await onAuthStateChanged(auth, (user) => {
    userObject = user;
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      userObject = user;
    } else {
      console.error("No user logged in.");
    }
  });
  return userObject;
}


/**
 * Get the user role of the user with the given user ID.
 * @param {*} currentUserID String. The ID of the user whose role is to be retrieved.
 * @returns String. The role of the user.
 */
const getCurrentUserRole = async (currentUserID) => {
  const docRef = doc(userRef, currentUserID);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data().role;
  } else {
    console.error("No such document!");
  }

}
    

/**
 * Delete the comment with the given comment ID from the parent post with the given post ID. 
 * Decrement the comment count in the parent post.
 * @param {*} postID String. The ID of the parent post
 * @param {*} commentID String. The ID of the post to be deleted
 */
const deleteComment = async (postID, commentID) => {
  const docRef = doc(postRef, postID);
  const replyCollection = collection(docRef, "replies");
  const commentRef = doc(replyCollection, commentID);
  await deleteDoc(commentRef);
  await updateDoc(docRef, {
    comments: increment(-1)
  });
}

const removeCommentReports = async (postID, commentID) => {
  try{
    console.log("removeCommentReports");
    console.log(postID);
    console.log(commentID);
    const docRef = doc(postRef, postID);
    const replyCollection = collection(docRef, "replies");
    const commentRef = doc(replyCollection, commentID);
    await updateDoc(commentRef, {
        reports: {
          reportDetails: [],
          count: 0
        },
        flagged: false
    });
    console.log("removeCommentReports done");
    return true;
  } catch {
    return false;
  }
  
}

const getComments = async (postID) => {
  const docRef = doc(postRef, postID);
  const replyCollection = collection(docRef, "replies");
  const querySnapshot = await getDocs(replyCollection);
  let comments = [];
  querySnapshot.forEach((doc) => {
    comments.push(doc.data());
  }
  );
  return comments;
}

/**
 * Obtain the data from a given post id.
 * @param {postId used to get post data} postID 
 * @returns data of the post with the given id
 */
const getPost = async (postID) => {
  const docRef = doc(postRef, postID);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.error("No such document!");
  }
}

/**
 * Delete the post with the given post ID.
 * @param {*} postID String. The ID of the post to be deleted
 * 
 */
const deletePost = async (postID) => {
  const docRef = doc(postRef, postID);
  await deleteDoc(docRef)
}

/**
 * Update the user name and image for each comment made by the user in the database using collection groups.
 * @param {*} userID String. The ID of the user being updated
 * @param {*} newImageUrl String. The URL of the new user image in Firebase Storage
 * @param {*} newName String. The user's new name
 */
const updateCommentNameAndImage = async (userID, newImageUrl, newName) => {
  const userComments = query(collectionGroup(fbFirestore, "replies"), where("userID", "==", userID));
  const querySnapshot = await getDocs(userComments);
  querySnapshot.forEach(async (element) => {
    const parentPostID = element.ref.parent.parent.id;
    const docRef = doc(postRef, parentPostID);
    const replyCollection = collection(docRef, "replies");
    const commentRef = doc(replyCollection, element.id);
    if (newImageUrl) {
      await updateDoc(commentRef, {
        image: newImageUrl,
        name: newName
      });
    } else {
      await updateDoc(commentRef, {
        name: newName
      });
    }
  })
}

const updateUserProfile = async (userID, newProfile) => {
  console.log("updating user profile: " + userID);
  console.log(newProfile.bio);
  console.log(newProfile.displayName);
  console.log(newProfile.email);
  console.log(newProfile.photoPath);
  console.log(newProfile.photoURL);
  const userDocRef = doc(userRef, userID);
  try {
    await updateDoc(userDocRef, {
      bio: newProfile.bio,
      displayName: newProfile.displayName,
      email: newProfile.email,
      linkedinURL: newProfile.linkedin,
      instagramURL: newProfile.instagram,
      twitterURL: newProfile.twitter,
      photoPath: newProfile.photoPath,
      photoURL: newProfile.photoURL
    })
  } catch (err) {
    console.log(err);
  }
}

export {
  getNotifications, addNotification, clearNotifications, getPosts, getPostInfo, getFirstPostsByDate, getNextPostsByDate, getUserInfo, updateUserLikedPosts, updateUserLikedComments,
  updatePostLikes, updateReplyLikes, getReplies, addReply, makePost, checkAuth, updatePost, deleteComment, getComments, updateCommentNameAndImage, upsertPost, uploadComment,
  addReport, updateBio, updateLinkedin, updateInstagram, updateTwitter, updatePostReports, updateCommentReports, updateRole, updateUserReportedPosts,  likeComment,likePost,
  deletePost, removePostReports, hasUserReportedPost, hasUserReportedComment, followRequest, acceptRequest, rejectRequest, followUser, unfollowUser, removeFollower, getPost, getUsers, upsertCommunity, 
  getCommunities, addUserToCommunity, removeUserFromCommunity, getCommunity, getCurrentUserRole, getCommunitiesJoined, getCommunitiesModerated, getUsersByRole,
  removeCommentReports, updateUserProfile, getFirstUserPostsByDate, getNextUserPostsByDate
};
