/**
 * This page displays posts and comments in the Community tab
 */

import React, { useState, useEffect } from 'react'
import { getFirstPostsByDate, getNextPostsByDate, getUserInfo, getCommunity } from '../firebase/firebaseFunctions';
import '../styles/blog.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserAuth } from '../context/AuthContext';
import Filter from 'bad-words';
import Loading from '../components/loading/loading';

import Post from '../components/community/post';

import { auth, fbFirestore } from "../firebase/firebaseConfig";
import PostEditor from '../components/community/postEditor';
import { onSnapshot, collection } from '@firebase/firestore';
import { query, where, orderBy, limit, getDocs, startAfter } from '@firebase/firestore';
import { getUsers } from '../firebase/firebaseFunctions';

import { useParams } from 'react-router-dom';
import CommunityInfo from '../components/community/CommunityInfo';
import Logo from '../imgs/logo.png';

import { stateFromHTML } from 'draft-js-import-html';
import { EditorState } from 'draft-js';
import ReactHtmlParser from 'react-html-parser';


const domain = "https://ux-was-here.web.app";
// const editorTitle = "Create Post";

const sharestyle = {
  margin: 'auto',
};

// The number of posts loaded initially and retrieved when the "Load More Posts" button is clicked
const postsRetrieved = 5;

// The name that will be added to a comment if the commentor is not signed in
const guestUserName = "Guest";
// The default guest avatar
const guestImageURL = "https://firebasestorage.googleapis.com/v0/b/ux-was-here.appspot.com/o/profile_img%2Fguest_profile.png?alt=media&token=70f3d2b4-88a8-4968-96d5-90a81c403a40";
// The label for the like/upvote button
const voteLabel = "Upvote";
const hasVotedLabel = "Upvoted";

// The Font Awesome class names to make the thumbs up icon filled in or regular.
const filledInThumb = "fas";
const regularThumb = "far";

let filter = new Filter();

const Community = () => {

  const { user } = UserAuth();
  const [loaded, setLoaded] = useState(false);

  const [communityInfo, setCommunityInfo] = useState();
  const [communityCreator, setCommunityCreator] = useState();

  let userData;
  let postNum = 0;

  useEffect(() => {
    const currentRoute = window.location.pathname;
    localStorage.setItem('currentRoute', currentRoute);
  }, []);

  const [postData, setPostData] = useState("");
  const [postsArray, setPostsArray] = useState([]);
  const [lastDoc, setLastDoc] = useState();
  const [allDocsLoaded, setAllDocsLoaded] = useState(false);
  const [likedPostsArray, setLikedPostsArray] = useState([]);
  const [likedCommentsArray, setLikedCommentsArray] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const [initialText, setInitialText] = useState("");

  const postLimit = 5;
  let community_ID = useParams().id;

  const openCreatePostEditor = () => {
    setPostData("");
    setInitialText("");
  }

  async function initializeUxWasHere(q) {
    try {

      const [userData, querySnapshot, users] = await Promise.all([
        getUserInfo(user.uid),
        getDocs(q),
        getUsers(),
      ]);
  
      setUserInfo(userData);
      
      const communityCreator = {
        displayName: "UX Was Here",
      };
      setCommunityCreator(communityCreator);
  
      const uxWasHereCommunityInfo = {
        name: "UX Was Here",
        bio: "Join a discussion with everyone in the General Ux Was Here community!",
        image: Logo,
        creatorID: "UX Was Here",
      }
  
      setCommunityInfo(uxWasHereCommunityInfo);
    
      // Store the initial posts in an array
      const initialPosts = [];
      querySnapshot.forEach((doc) => {
        initialPosts.push(doc);
      });
      setPostsArray(initialPosts);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
  
      setLoaded(true);

    } catch (error) {
      console.log(error);
    }
  }

  async function initializeRegularCommunity(q) {
      const [userData, querySnapshot, communityInfo] = await Promise.all([
        getUserInfo(user.uid),
        getDocs(q),
        getCommunity(community_ID),
      ]);

      setUserInfo(userData);
      setCommunityInfo(communityInfo.data());

      const communityCreator = await getUserInfo(communityInfo.data().creatorID);
      setCommunityCreator(communityCreator.data());
    
      // Store the initial posts in an array
      const initialPosts = [];
      querySnapshot.forEach((doc) => {
        initialPosts.push(doc);
      });
      setPostsArray(initialPosts);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);

      setLoaded(true);
  }

  // Get the signed in user's info. Create an array of posts and an array of comments that the user has upvoted
  // Get the first {postsRetieved} posts sorted by date and add them to postsArray. Sets the lastDoc, which is the
  // last doc retrieved from the database (needed for pagination)
  useEffect(() => {
    const postRef = collection(fbFirestore, "posts");

    const get = async () => {

      // Fetch the initial 5 most recent posts that are not flagged
      const q = community_ID !== undefined ? 
      query (
        postRef,
        where("flagged", "==", false),
        where("community_ID", "==", community_ID),
        orderBy("time", "desc"),
        limit(postLimit)
      ) : query (
        postRef,
        where("flagged", "==", false),
        where("community_ID", "==", null),
        orderBy("time", "desc"),
        limit(postLimit)
      );

      if (community_ID === undefined) {
        initializeUxWasHere(q);
      } else {
        initializeRegularCommunity(q);
      }

      const bioElement = document.getElementById('communityBio');
      bioElement.innerHTML = communityInfo?.bio;
  
    };
    get();

  
    // Set up a snapshot listener to listen for new posts or updates to existing posts
    // TODO: make it so that it updates when the post is also deleted, edited
    // const q = community_ID !== undefined ? query(
    //   postRef,
    //   where("flagged", "==", false),
    //   where("community_ID", "==", community_ID),
    //   orderBy("time", "desc"),
    //   limit(1)
    // ) : query(
    //   postRef,
    //   where("flagged", "==", false),
    //   where("community_ID", "==", null),
    //   orderBy("time", "desc"),
    //   limit(1)
    //   );

  // const unsub = onSnapshot(q, (snapshot) => {
  //   snapshot.docChanges().forEach((change) => {
  //     const post = change.doc;
      
  //     if (change.type === "added") {
  //       // Check if the post already exists in the postsArray
  //       const postExists = postsArray.some((prevPost) => prevPost.id === post.id);
  //       if (!postExists) {
  //         // Add the new post to the postsArray only if it's not the most recent post
  //         if (change.newIndex !== 0) {
  //           setPostsArray((prevPosts) => [post, ...prevPosts]);
  //         }
  //       }
  //     } else if (change.type === "modified") {
  //       // Update the existing post in the postsArray
  //       setPostsArray((prevPosts) => {
  //         const updatedPosts = prevPosts.map((prevPost) =>
  //           prevPost.id === post.id ? post : prevPost
  //         );
  //         return updatedPosts;
  //       });
  //     }
  //  });

  
    return () => {
      //unsub();
    };
  }, []);

  /**
   * Retrieve the next {postsRetrived} posts when the load more button is clicked.
   * Posts are sorted by date, with newest posts first. Add the posts to postsArray
   */
  const fetchMore = async () => {
    if (lastDoc) {
      const postRef = collection(fbFirestore, "posts");
      const querySnapshot = await getDocs(
        community_ID !== undefined ? query(
          postRef,
          where("flagged", "==", false),
          where("community_ID", "==", community_ID),
          orderBy("time", "desc"),
          startAfter(lastDoc),
          limit(5)
        ) : 
        query(
          postRef,
          where("flagged", "==", false),
          where("community_ID", "==", null),
          orderBy("time", "desc"),
          startAfter(lastDoc),
          limit(5)
      ));
  
      const nextPosts = [];
      querySnapshot.forEach((doc) => {
        nextPosts.push(doc);
      });
  
      setPostsArray((prevPosts) => [...prevPosts, ...nextPosts]);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);

      if (querySnapshot.docs.length < postLimit) {
        setAllDocsLoaded(true);
      }
    }
  }

  function htmlStringToDraftState(htmlText) {
    const contentState = stateFromHTML(htmlText);
    const editorState = EditorState.createWithContent(contentState);

    const renderedHTML = ReactHtmlParser(editorState.getCurrentContent().getPlainText());
    return renderedHTML;
  }

  return (
    <div className='communityContainer'>
      <div className="container-blogs">

         <header style={{ backgroundImage: `url(${communityInfo?.image})` }} className="communityHeader">
            <div className="headerCard">
              <h1>{communityInfo?.name}</h1>
              <div dangerouslySetInnerHTML={{ __html: communityInfo?.bio }}></div>
              {
                communityInfo?.creatorID === "UX Was Here" && <div>Members: Everyone</div>
              }
              {
                communityInfo?.creatorID !== "UX Was Here" && 
                  <div>{communityInfo?.userIDs?.length} Member{communityInfo?.userIDs?.length != 1 && "s"}</div>
              }
              <div>Created by {communityCreator?.displayName}</div>
            </div>
         </header>

        {postsArray.length === 0 && !loaded && <Loading /> }


        {loaded && <CommunityInfo userPostsLink={"/profile/" + auth.currentUser.uid} user={user} openCreatePostEditor={openCreatePostEditor} />}
        {(loaded && auth.currentUser === null) && <div className="no-posts">No Posts</div>}


        {postsArray.length === 0 && loaded && 
        <div className="no-posts">
          <p>No posts in this community yet!</p>
          <p className='far fa-folder-open'></p>
          </div>}
        
        {postsArray.length !== 0 && loaded && <>
          <div className="postArrayContainer" id="profile-post">
            {postsArray.map((post) => <Post user={userInfo} data={post} postNum={postNum++} setPostData={setPostData} />)}
          </div>
        </>
        }
      </div>
      <div className='load-btn-div'>
        {!allDocsLoaded && postsArray.length !== 0 && <button className='communityBtn btn btn-primary load-btn' onClick={fetchMore}>Load More Posts</button>}
      </div>

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />

      <PostEditor data={postData} community_ID={community_ID}/>
    </div>
  )
}; export default Community;